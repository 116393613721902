
/**
 * 
 * @param  {...any} props 
 * @return {{any}}
 */
export const MergeAll = (...props) => {
    if (props.length < 1){
        return {};
    }else if (props.length === 1){
        return Object.assign({}, props[0]);
    }else {
        return Object.assign(Object.assign({}, props[0]), MergeAll(...(props.slice(1))))
    }
}

/**
 * 
 * @param {any[][]} param0 
 * @returns {any[]}
 */
export const ConcatAll = ([...props]) => {
    if (props.length === 0){
        return [];
    }else if (props.length === 1){
        return props[0];
    }else{
        return props[0].concat(ConcatAll(props.slice(1)))
    }
}

/**
 * 
 * @param {HTMLElement} DOM 
 * @returns {{top: number, left: number}}
 */
export const getAbsoluteOffset = (DOM) => {
    let offsetLeft = 0
    let offsetTop = 0

    let indicator = DOM
    do {
        offsetTop += indicator.offsetTop
        offsetLeft += indicator.offsetLeft
        indicator = indicator.parentElement
    } while (indicator.tagName !== 'HTML')

    return {top: offsetTop, left: offsetLeft}
}
/**
 * 
 * @param {HTMLElement} DOM 
 * @returns {{top: number, left: number, height: number, width: number}}
 */
export const getOffsetInScreen = (DOM) => {
    if (!DOM){
        return {};
    }
    return DOM.getBoundingClientRect();
}

/**
 * 
 * @param {number} rem 
 * @returns {number} fontSize * rem in pixel
 */
export const pixelize = (rem) => (
    rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize || 16)
);

/**
 * @typedef {{x: number, y: number}} point 
 * @typedef {{width: number, height: number}} size 
 */

/**
 * @param {number} total
 * @param {number} index 
 * @param {point} origin 
 * @param {size} size 
 * @param {number} padding 
 */
export const gridAllocation = (total, index, origin, size, padding) => {
    /** @type {point} */
    
    let grids = [];

    let item_in_a_row = Math.ceil(Math.sqrt(total))
    if (item_in_a_row % 2 === 0){
        item_in_a_row ++;
    }
    let center = {x: Math.floor(item_in_a_row/2), y: Math.floor(item_in_a_row/2)};
    
    for (let i = 0; i < item_in_a_row; i++){
        for(let j = 0; j < item_in_a_row; j++){
            grids.push({
                distance: Math.pow(i - center.x, 2) + Math.pow(j - center.y, 2),
                point: {x: i - center.x, y: j - center.y}
            })
        }
    }

    let sorted_grids = grids.sort((a,b) => a.distance - b.distance);
    let cssPoint = {
        left: origin.x + sorted_grids[index].point.x * (size.width + padding),
        top: origin.y + sorted_grids[index].point.y * (size.height + padding)
    }
    
    return MergeAll(cssPoint, size, {padding});
}

export const serviceAlias = {
   naver: ['naver', '네이버', '네이버 웹툰',]
   , daum: ['daum', '다음', '다음 웹툰',]
   , kakao: ['kakao', '카카오', '페이지', '카카오페이지', '카카오 페이지',]
   , toomics: ['toomics', '투믹스',]
   , toptoon: ['toptoon', '탑툰',]
   , lezhin: ['lezhin', '레진', '레진 코믹스',]
   , comico: ['comico', '코미코']
   , today: ['today', '오늘의웹툰']
}

/**
 * 
 * @param {object} key 
 * @param {{
 *   validator: (object) => boolean,
 *   sublogic?: logic | object,
 *   defaultValue: object
 * }[]} logic 
 * @param {object} defaultValue
 * @return {object | null}
 */
export const BranchFunction = (key, logic, defaultValue) => {

    if (logic instanceof Array){
        for(const {validator, sublogic, defaultValue} of logic){
            if (validator(key)){
                if (sublogic && sublogic instanceof Array && sublogic.length > 0){
                    return BranchFunction(key, sublogic, defaultValue);
                }else{
                    return defaultValue;
                }
            }else {
            }
        }
    }
    return defaultValue;

}


export const gradient = (progress, start, middle, end) => end === undefined
?(start + (middle - start) * progress)
:progress>0.5
    ?(middle + (end    - middle) * (progress/0.5 - 1) )
    :(start  + (middle - start ) * (progress/0.5    ) );

/**
 * 
 * @param {boolean} proxy 
 * @param {string} url 
 */
export const thumbnailize = (url,max_width=0,max_height=0) => (
    !url
        ?url
        :`https://cached-api.webtoon.today/thumb?u=${encodeURIComponent(url)}&agent${max_width>0?`&mw=${max_width*2}`:''}${max_height>0?`&mh=${max_height*2}`:''}`
)

export const waitImageLoaded = (imageUrl) => {
    let smallImage = document.createElement('img');
    smallImage.setAttribute('style', 'width:1px; opacity:0;');
    const imagePromise = new Promise((resolve,reject) => {
        smallImage.onload = () => {
            resolve({width: smallImage.naturalWidth || 16, height: smallImage.naturalHeight || 9})
            document.body.removeChild(smallImage)
        }
        smallImage.onerror = (error) => {
            reject(error)
            document.body.removeChild(smallImage)
        }
    })
    document.body.appendChild(smallImage);
    smallImage.setAttribute('src', imageUrl);

    return imagePromise;
}

export const wait = (ms) => {

    return new Promise( (resolve, reject) => setTimeout(()=>resolve(true), ms) );
}

export const decodeEntities = (str) => {
        // this prevents any overhead from creating the object each time
        let element = document.createElement('div');
    
        const decodeHTMLEntities = (str) => {
            if(str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }
        
            return str;
        }
    
        return decodeHTMLEntities(str);
};

/**
 * 
 * @param {Set} setA 
 * @param {Set} setB 
 */
export const setUnion = (setA, setB) => {
    let ret = new Set();

    for(const elem of setA){
        ret.add(elem);
    }
    for(const elem of setB){
        ret.add(elem);
    }

    return ret;
}
/**
 * 
 * @param {Set} setA 
 * @param {Set} setB 
 */
export const setIntersect = (setA, setB) => {
    let ret = new Set();

    for(const elem of setA){
        if (setB.has(elem)){
            ret.add(elem);
        }
    }

    return ret;
}
/**
 * 
 * @param {Set} setA 
 * @param {Set} setB 
 */
export const setSubtraction = (setA, setB) => {
    let ret = new Set();

    for(const elem of setA){
        if (!setB.has(elem)){
            ret.add(elem);
        }
    }

    return ret;
}

export const accountNumberize = (number) => {

    let valueString = '-' 
    if (isNaN(number)){
        valueString = 'NaN'
    }else if (number === 0){
        valueString = '-'
    }else {
        valueString = ''
        let numberString = (number+'').split('').reverse().join('')
        if (numberString.indexOf('.') >= 0){
            valueString = numberString.split('.')[0]+".";
            numberString = numberString.split('.')[1];
        }
        while (numberString.length > 0){
            valueString += numberString.substr(0,3)
            numberString = numberString.substr(3)
            if (numberString.length > 0){
                valueString += ','
            }
        }

        valueString = valueString.split('').reverse().join('')
    }

    return valueString
};

export const numberizeOrNot = (str) => {
    if (isNaN(str.replace(/,/g,''))){
        return str;
    }else{
        Number(str.replace(/,/g,''))
    }
}

export const isNaAN = (str) => {
    return !str || ( isNaN(str) && typeof(str) === 'string' && isNaN(str.replace(/,/g,'')) )
}

/**
 * @typedef {import('./Data/Title').titleType} titleType
 * 
 * @param {number} gridInARow 
 * @param {{item: titleType, importance: 0|1|2, direction: 'horizontal'|'vertical'|'rect'}[]} items 
 */
export const instaGridConstruct = (gridInARow, items) => {
    
    let grid = [];
    
    // eslint-disable-next-line no-empty-pattern
    for(const {} of items){
        grid.push(Array.from(new Array(gridInARow), ()=>0));
        grid.push(Array.from(new Array(gridInARow), ()=>0));
        grid.push(Array.from(new Array(gridInARow), ()=>0));
    }

    const itemPositionDict = {}
    
    for(const {item, importance, direction} of items){
        let itemAllocated = false;
        for (let row = 0; row < grid.length; row ++){
            if (itemAllocated){
                break;
            }
            for(let col = 0; col < gridInARow; col ++){
                if (itemAllocated){
                    break;
                }
                if (!grid[row][col]){
                    if (importance === 0){
                        grid[row][col] = 1
                        itemPositionDict[`${item.serviceId}:${item.titleId}`] = [row, col]
                        itemAllocated = true
                    }else if (importance === 1 && direction === 'vertical'){
                        if (!grid[row+1][col]){
                            itemPositionDict[`${item.serviceId}:${item.titleId}`] = [row, col]
                            grid[row  ][col]++
                            grid[row+1][col]++
                            itemAllocated = true
                        }
                    }else if (importance === 1 && (direction === 'horizontal' || direction === 'rect')){
                        if (   col + 1 < gridInARow
                            && !grid[row][col+1] ){
                            itemPositionDict[`${item.serviceId}:${item.titleId}`] = [row, col]
                            grid[row][col  ]++
                            grid[row][col+1]++
                            itemAllocated = true
                        }
                    }else if (importance === 2 && direction === 'rect'){
                        if (   col + 1 < gridInARow
                            && !grid[row  ][col+1]
                            && !grid[row+1][col  ]
                            && !grid[row+1][col+1] ){
                            itemPositionDict[`${item.serviceId}:${item.titleId}`] = [row, col]
                            grid[row  ][col  ]++
                            grid[row  ][col+1]++
                            grid[row+1][col  ]++
                            grid[row+1][col+1]++
                            itemAllocated = true
                        }
                    }else if (importance === 2 && direction === 'horizontal'){
                        if (   col + 2 < gridInARow
                            && !grid[row  ][col+1]
                            && !grid[row  ][col+2]
                            && !grid[row+1][col  ]
                            && !grid[row+1][col+1]
                            && !grid[row+1][col+2] ){
                                itemPositionDict[`${item.serviceId}:${item.titleId}`] = [row, col]
                                grid[row  ][col  ]++
                                grid[row  ][col+1]++
                                grid[row  ][col+2]++
                                grid[row+1][col  ]++
                                grid[row+1][col+1]++
                                grid[row+1][col+2]++
                                itemAllocated = true
                            }
                    }else if (importance === 2 && direction === 'vertical'){
                        if (   col + 1 < gridInARow
                            && !grid[row+1][col  ]
                            && !grid[row+2][col  ]
                            && !grid[row  ][col+1]
                            && !grid[row+1][col+1]
                            && !grid[row+2][col+1] ){
                                itemPositionDict[`${item.serviceId}:${item.titleId}`] = [row, col]
                                grid[row  ][col  ]++
                                grid[row+1][col  ]++
                                grid[row+2][col  ]++
                                grid[row  ][col+1]++
                                grid[row+1][col+1]++
                                grid[row+2][col+1]++
                                itemAllocated = true
                            }
                    }
                }
            }
        }
    }
    grid = grid.filter(row => row.reduce( (a,b) => a+b, 0) > 0)

    return [grid, itemPositionDict]    
} 

export const randomString = (length) =>
  new Array(length).fill(0)
    .map((x) => String.fromCharCode(65 + Math.floor(Math.random() * 2) * 32 + Math.floor(Math.random() * 26)))
    .join("");

/**
 * @typedef T
 * 
 * @param {T} obj 
 * @param {number} index 
 * @param {T[]} arr 
 */
export const unique = (obj, index, arr) => {
    if (index === arr.indexOf(obj)){
        return true;
    }else {
        return false;
    }
}

/**
 * 
 * @param {Date} date 
 */
export const dateFormat = (date) =>{
    let todayZeroAM = new Date();
    todayZeroAM.setHours(0,0,0,0);

    if (todayZeroAM - date < 2 * 24 * 60 * 60 * 1000){
        if (todayZeroAM - date < 0 ){
            return `오늘`;
        }else if (todayZeroAM - date < 1 * 24 * 60 * 60 * 1000){
            return `어제`;
        } else {
            return `이틀 전`;
        }
    }
    
    let thisMonthFirst = new Date();
    thisMonthFirst.setHours(0,0,0,0);
    thisMonthFirst.setDate(1);

    if (todayZeroAM - date < (365 - 31) * 24 * 60 * 60 * 1000){
        return `${('00'+(date.getMonth()+1)).substr(-2)}/${('00'+date.getDate()).substr(-2)}`
    }

    return `${date.getFullYear()}`;

}

export const fn = {
    goto: ()=>{},
    gotoByAnchor: (event)=> {
        event.preventDefault();
        /**
         * @type {HTMLElement}
         */
        let target = event.target
        
        while (target.tagName !== 'body' && !target.getAttribute('href')){
            target = target.parentElement
        }
        if (target.tagName === 'body'){
            return;
        }else{
            fn.goto(target.getAttribute('href'));
        }
    },
    setQuery: (query) => {}
};