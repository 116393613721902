import React from 'react';

const data = {
    title: "개인정보 처리 방침"
    ,body: "다음 개인정보 처리 방침(이하 방침)은 이 서비스에서 수집하거나 제공하는 개인정보를 처리하는 방법에 대해 설명합니다. 또한 이 방침은 서비스를 이용하는 과정에서 어떤 개인정보를 수집하거나 제공하는지 설명합니다. 오늘의 웹툰은 본 서비스와 연관 서비스로의 개인정보 수집과 처리의 주체입니다. 이 방침을 통해 오늘의 웹툰은 유저의 개인정보의 중요성을 표하고, 개인정보를 활용하는 방법과 목적, 어떤 방법으로 개인정보를 보호하는지를 고지합니다."
    ,nested: [
        {
            title: "수집된 정보의 사용"
            , prefix: "i"
            , body: "오늘의 웹툰은 다음의 목적으로 정보를 수집합니다:"
            , nested: [
                {title: "회원 관리와 식별", prefix:'-'},
                {title: "서비스 개선과 신규 기능 개발", prefix:'-'},
                {title: "법적 의무 및 유관 법률 준수", prefix: '-'}
            ]
        },
        {
            title: "수집된 정보의 노출"
            , prefix: "ii"
            , body: "오늘의 웹툰은 그 어떤 개인정보도 제 3자에게 제공하지 않습니다."
            , nested: [
            ]
        },
        {
            title: "사용자의 권리"
            , prefix: "iii"
            , body: "사용자 또는 그의 법적 대리인은 개인정보의 주체로서, 오늘의 웹툰에서 발생하는 개인정보 수집, 사용 및 공유에 대해 다음과 같이 권리를 행사할 수 있습니다:"
            , nested: [
                {title: "개인정보 조회에 대한 권리", prefix:'-'},
                {title: "기존에 동의한 사항을 취소할 권리", prefix:'-'},
                {title: "개인정보 처리를 일시적으로 중지할 권리", prefix:'-'},
            ]
        },
        {
            title: "수집되는 개인정보 및 이를 수집하는 방법"
            , prefix: "iv"
            , body: "오늘의 웹툰은 다음과 같은 사항을 수집합니다:"
            , nested: [
                {title: "암호화된 Google 식별자, Email, name(Google SSO를 사용하는 경우)", prefix:'-'},
                {title: "암호화된 Facebook 식별자, Email, name(Facebook SSO를 사용하는 경우)", prefix:'-'},
                {title: "브라우저의 고유 명칭(user agent)", prefix:'-'},
                {title: "접속 주소(IP)", prefix:'-'},
                {title: "서비스를 사용하면서 발생한 상호작용 정보", prefix:'-'},
            ]
        },
        {
            title: "구글 사용자 정보의 수집과 목적"
            , prefix: "v"
            , body: "오늘의 웹툰은 아래 표기된 목적과 방법으로 구글 사용자 정보를 수집합니다:"
            , nested: [
                {title: "구글 로그인(Google SSO)과정을 통해", prefix:'-'},
                {title: "제한된 범위의 사용자 정보(user id, name, email)를 수집합니다. 이는", prefix:'-'},
                {title: "사용자를 다른 이들과 구분짓기 위함이며", prefix:'-'},
                {title: "개인화된 추천을 제공하기 위함입니다..", prefix:'-'},
                {title: "오늘은 구글로부터 제공받은 어떠한 정보도 외부로 공유하지 않습니다.", prefix:'-'},
            ]
        },
        {
            title: "보안"
            , prefix: "vi"
            , body: "오늘의 웹툰은 개인정보 보안이 매우 중요하다고 인지하고 있습니다. 오늘의 웹툰은 다음과 같은 보안 방법을 구축하여 비인가 접근, 배포, 사용 또는 변형으로부터 개인정보를 보호하고 있습니다."
            , nested: [
                {title: "개인정보의 암호화", prefix: '-'},
                {title: "해킹 역탐지", prefix: '-'},
            ]
        },
        {
            title: "쿠키 및 유사 기술"
            , prefix: "vii"
            , body: "오늘의 웹툰은 쿠키를 이용하여 웹서비스 사용 정보를 수집합니다. 유저는 쿠키 수집에 대해 선택권이 있습니다. 브라우저 설정을 통해 사이트의 모든 쿠키 수집을 차단할 수 있으며, 저장된 시점에 무엇이 저장되었는지를 확인하거나 저장되지 않도록 할 수 있습니다: 설명한 바와 같이, 쿠키를 사용하지 않도록 하는 것은 사용자를 식별하기 어렵게 만들며 온전한 서비스를 제공받을 수 없음을 의미합니다."
            , nested: [
            ]
        },
        {
            title: "연락 정보"
            , prefix: "viii"
            , body: "오늘의 웹툰이 이 방침에 따라 처리해야 하는 문의사항이 있거나, 개인정보에 대한 권리를 행사하는 경우 아래의 연락처로 문의주세요:"
            , nested: [
                {title: "privacy@webtoon.today"}
            ]
        },
    ]
};
/*
        {
            title: ""
            , prefix: ""
            , body: ""
            , nested: [
            ]
        }
 */

const DrawData = (data, prefix, depth) => {
    return (
        <div style={{paddingLeft: depth * 20, fontSize: 120*Math.pow(0.9, depth)+'%' }}>
            <p>
                {data.prefix?`${data.prefix}`:''} {data.title || ""}
            </p>
            {data.body || ""}
            {data.nested?data.nested.map(row => DrawData(row, prefix?`${prefix}.${data.prefix}`:data.prefix, depth+1)):''}
        </div>
    );
}


export default function PrivacyPolicyKr (props) {

    window.canonicalPath = '/privacypolicy/kr';

    return (
        <div style={{textAlign:'left'}}>
            {DrawData(data, "", 1)}
        </div>
    );
}