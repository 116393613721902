import axios from 'axios';
import ss from './Session';

// @type
// eslint-disable-next-line no-unused-vars
import {sessionType} from './Session';

/**
 * 
 * @typedef {{
      serviceId: string, titleId: string, title: string, thumbnail: string, tags: string,
      lastUpdatedAt: number, lastEpisodeId: string,
      lastEpisodeTitle: string, lastEpisodeHref: string, lastEpisodeThumbnail: string,
      description: string,
    }} featuredType 
 */

let memStore = {};

/**
 * 
 * @param {string} query 
 * @param {number} limit 
 * @returns {Promise<[featuredType]>}
 */
export async function readFeatured (limit, type, query, negativeQuery, history) {

    if (history && history.length === 0){
        history = undefined;
    }

    if (!history){
        if (memStore['featured?'+JSON.stringify({limit, type, query, negativeQuery})]){
            return memStore['featured?'+JSON.stringify({limit, type, query, negativeQuery})];
        }
    }

    try{
        let res = await axios.post(`https://challenge-api.webtoon.today/timeline`, {
                start_index: 0
            }, {
                headers: {
                    Authorization: `Bearer ${ss.getCurrentSession().jwt}`
                },
                withCredentials: true
            });

        if (res.data && res.data.code === 200){

            if (!history){
                memStore['featured?'+JSON.stringify({limit, type, query, negativeQuery})] = res.data.data;
            }
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        console.error(e)
        return [];
    }
}