//import axios from 'axios';

const TAGS = {
    daily_routine: '일상', comedy: '개그', fantasy: '판타지', action: '액션',
    drama: '드라마', pureLove: '순정', sensibility: '감성', thriller: '스릴러', 
    sport: '스포츠', martial_arts_historical_drama: '무협/사극',
};

/**
 * 
 * @param {'popular'|'recency'} sort 
 * @param {0|1|2} diversity 
 * @param {number} limit 
 */
export const readTags = async (sort='popular', diversity=2, limit = 20) => {
    let ret = [];

    ret = Object.values(TAGS).map(tag => ({tag, }));
    
    return ret;
};