import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *  userid: string,
 *  addressid: string,
 *  name: string | null,
 *  email: string | null,
 *  image: string | null,
 * }} profileType
 */

/**
 * 
 * @param {{
 *  name?: string,
 *  email?: string,
 *  addressid?: string,
 *  image?: string
 * }} newProfileData
 * @returns {Promise<import('./Session').sessionType|false>}
 */
export const updateProfile = async (newProfileData) => {
    let res = await axios.patch('https://challenge-api.webtoon.today/profile', newProfileData, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else{
        return false;
    }
};

/**
 * 
 * @param {string} targetid
 * @returns {Promise<import('./Session').sessionType>}
 */
export const readProfile = async (targetid) => {
    let res = await axios.post(`https://challenge-api.webtoon.today/profile?targetid=${targetid}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return {
            userid: null,
            email: null,
            image: null,
            addressid: null,
        }
    }
}