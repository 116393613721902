import axios from 'axios';

/**
 * @typedef {{
 *  googleId: string,
 *  ...
 * }} googleResponseType
 * @typedef {{
 *   accessToken: string
 * }} facebookResponseType
 * @typedef {import('./Profile').profileType&{
 *  token: string,
 * }} sessionType
 * @typedef {{status: 200|any, data:{
 *  code: number,
 *  data: sessionType
 * }}} sessionResponseType 
 */

const nullSession = {userid:null, token:null, name: null, image: null, email: null};
const crnt = {session: global.localStorage?JSON.parse(localStorage.getItem('session') || '{"jwt":""}'):nullSession};

const sessionRefresh = async () => {
    const session = JSON.parse(localStorage.getItem('session') || '{"jwt":""}');

    /** @type {sessionResponseType} res */
    let res = await axios.post(
        'https://challenge-api.webtoon.today/session',
        (session.userid && session.token)?{userid: session.userid, token: session.token}:{}
    );

    if (res.data.code === 200 && res.data.data && res.data.data.userid){
        crnt.session = res.data.data;
        localStorage.setItem('session', JSON.stringify(res.data.data));
    }else{
        crnt.session = nullSession;
        localStorage.setItem('session', JSON.stringify(nullSession));
    }

    return crnt.session;

}

const logout = () => {
    crnt.session = nullSession;

    localStorage.setItem('session', JSON.stringify(nullSession));
}

/**
 * @param {googleResponseType} response
 */
const GoogleSignin = async (response, ) => {
    if (window.gtag){
        window.gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    if (response.googleId){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {gsso: response}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                crnt.session = res.data.data;
                localStorage.setItem('session', JSON.stringify(res.data.data));
                return true;
            }else{
                crnt.session = nullSession;
                localStorage.setItem('session', JSON.stringify(nullSession));
            }
        }catch(e){
            console.log(e)
            // do nothing
            return false;
        }
    }
    return false;
}

/**
 * @param {facebookResponseType} response
 */
const FacebookSignin = async (response, ) => {
    if (window.gtag){
        window.gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    if (response.accessToken){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {fsso: response}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                crnt.session = res.data.data;
                localStorage.setItem('session', JSON.stringify(res.data.data));
                return true;
            }else{
                crnt.session = nullSession;
                localStorage.setItem('session', JSON.stringify(nullSession));
            }
        }catch(e){
            console.log(e)
            // do nothing
            return false;
        }
    }
    return false;
}

/**
 * @param {(session: sessionType) => void} sessionCallback 
 * @returns {{
 *  sessionRefresh: ()=> void,
 *  logout: () => void,
 *  GoogleSignin: (response: googleResponseType) => Promise<void>,
 *  FacebookSignin: (response: facebookResponseType) => Promise<void>,
 *  getCurrentSession: () => sessionType
 * }}
 */
const sessionFuncs = {
    sessionRefresh,
    logout,
    GoogleSignin,
    FacebookSignin,
    getCurrentSession: () => {return crnt.session}
};

export default sessionFuncs;