import {logBehavior} from './Behavior';
import ss from './Session';

let tokenSent = false;

export const hasPushPermission = () => {
    const result = ss.getCurrentSession().userid && window.Notification && window.Notification.permission === 'granted';
    if (result && !tokenSent){
        tokenSent = true;
        (async()=>{
            let currentToken = await window.fcm.getToken();
            if (currentToken){
                logBehavior('push_aquire_permission', {token:currentToken});
            }
        })();
    }
    return result;
}

/**
 * @param {(show: true|false) => void} setIsPushWarningShown 
 * @returns {string|boolean} token which acquires permission or false
 */
export const askPushPermission = async (setIsPushWarningShown) => {

    if (!window.fcm){
        console.log("[web push] fcm not exists");
        return false;
    }

    try{
        let currentToken = await window.fcm.getToken();

        if (currentToken){
            logBehavior('push_aquire_permission', {token:currentToken});
            return currentToken;
        }else{                
            console.log("fcm exists, no token");
            setIsPushWarningShown(true);
        }
    }catch(e){
        console.error(e)
        console.log("fcm exists, exception");
        setIsPushWarningShown(true);
    }

    return false;
};