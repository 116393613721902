import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import React from 'react';
import Swipe from '../Component/Swipe';
import VSCard from '../Component/VSCard';
import { listRecomm } from '../Data/Recomm';
import { MergeAll } from '../Functions';
import { FlatVSCard, SIGNATURE_COLOR } from '../Styles';
import {thumbnailize} from '../Functions';
import { logBehavior } from '../Data/Behavior';
/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
 */
const Match = (props) => {

    window.canonicalPath = '/match';

    const [candidates, setCandidates] = React.useState([]);

    React.useEffect(()=>{
        (async ()=>{
            const recomm = await listRecomm(
                null,
                Object.keys(props.interests.subscribeData).map( pair => ({serviceId: pair.split(':')[0], titleId: pair.split(':')[1]}) ),
                [],
            );
            setCandidates(recomm);
        })();
        console.log("interest update")
    },[props.interests, props.session])

    const [likeList, setLikeList] = React.useState([]);
    const [dislikeList, setDislikeList] = React.useState([]);

    React.useEffect(()=>{
        if (window.bulkProps.match){
            const {likeList, dislikeList} = window.bulkProps.match;
            setLikeList(likeList);
            setDislikeList(dislikeList);
        }
    },[]);
        
    React.useEffect(()=>{
        if (dislikeList.length === 0){
            return;
        }
        (async ()=>{
            const recomm = await listRecomm(
                null,
                Object.keys(props.interests.subscribeData).map( pair => ({serviceId: pair.split(':')[0], titleId: pair.split(':')[1]}) ).concat(likeList),
                dislikeList.concat(candidates),
                3
            );
            let existingKeys = candidates.concat(likeList).concat(dislikeList).map(row => `${row.serviceId}:${row.titleId}`);
            setCandidates(candidates.concat(recomm.filter(row => existingKeys.indexOf(`${row.serviceId}:${row.titleId}`)<0)).slice(0,1));
        })();
        console.log("dislike update")
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dislikeList]);

    React.useEffect(()=>{
        if (likeList.length === 0){
            return;
        }
        (async ()=>{
            const recomm = await listRecomm(
                likeList[likeList.length-1],
                Object.keys(props.interests.subscribeData).map( pair => ({serviceId: pair.split(':')[0], titleId: pair.split(':')[1]}) ).concat(likeList),
                dislikeList.concat(candidates),
                3
            );
            let existingKeys = candidates.concat(likeList).concat(dislikeList).map(row => `${row.serviceId}:${row.titleId}`);
            setCandidates(candidates.concat(recomm.filter(row => existingKeys.indexOf(`${row.serviceId}:${row.titleId}`)<0)).slice(0,1));
        })();
        console.log("like update")
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[likeList]);

    const [offset, setOffset] = React.useState({x:props.windowSize.width/2,y:0});
    const [internalOffset, setInternalOffset] = React.useState({x:props.windowSize.width/2,y:0});

    let width = props.windowSize.width;
    let height = props.windowSize.height;
    const style = {
        width: '100%',
        height: props.windowSize.height - 48,
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        overflow: 'hidden', 
        Selected: {
            position: 'fixed', top: 0, left: 0,
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
            Thumb: {

                width: 40, height: 40, margin: 5,
                borderRadius: 20,
                border: '1px rgba(0,0,0,0.45) dashed'
            },
        },
        LikeHighlight: {
            position: 'fixed', top: (height-40)/2, right: - width/6 -Math.max( (width / 3) * 2 * (internalOffset.x / props.windowSize.width -0.5), 0),
            width: width / 3,
            height: 40,
            opacity:1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row-reverse',
            transition: '100ms',
            Icon: {
                width: 40,
                height: 40,
                color: SIGNATURE_COLOR,
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: 20,
            }
        },
        DislikeHighlight: {
            position: 'fixed', top: (height-40)/2, left: - width/6 -Math.max( (width / 3) * 2 * (0.5 - internalOffset.x / props.windowSize.width), 0),
            width: width / 3,
            height: 40,
            opacity:1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            transition: '100ms',
            Icon: {
                width: 40,
                height: 40,
                color: 'red',
                backgroundColor: 'rgba(0,0,0,0.05)',
                borderRadius: 20,
            }
        }
    };

    const [isDialogOpen ,setIsDialogOpen] = React.useState(false);

    const keepAndViewSerial = () => {        
        props.saveAndUpdateInterest(likeList.map(item => [
            {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} },
            {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
        ]).flat());
        props.goto(`/detail/${likeList[0].serviceId}/${likeList[0].titleId}`);
        props.goto(likeList[0].firstEpisodeHref);
    }

    const currentItem = candidates[0];

    const likeItem = (by = 'button') => {        
        setCandidates(candidates.filter(row => row.serviceId !== currentItem.serviceId && row.titleId !== currentItem.titleId));
        if (likeList.length === 2){
            setIsDialogOpen(true);
        }
        setLikeList(likeList.concat([currentItem]));

        if (!window.bulkProps.match){
            window.bulkProps['match'] = {
                likeList: [], dislikeList: [],
            };
        }
        window.bulkProps.match.likeList = likeList.concat([currentItem]);
        logBehavior("likeItem", {currentItem, by});
    }

    const dislikeItem = (by = 'button') => {
        setCandidates(candidates.filter(row => row.serviceId !== currentItem.serviceId && row.titleId !== currentItem.titleId));
        setDislikeList(dislikeList.concat([currentItem]));
        
        if (!window.bulkProps.match){
            window.bulkProps['match'] = {
                likeList: [], dislikeList: [],
            };
        }
        window.bulkProps.match.dislikeList = dislikeList.concat([currentItem]);
        logBehavior("dislikeItem", {currentItem, by});
    }

    return (<div style={style}>
        <div style={style.Selected}>
            <div style={style.Selected.Thumb}>
                {likeList.length>0
                    ?<IconButton style={{padding:0,margin:0}} onClick={()=>props.goto(`/detail/${likeList[0].serviceId}/${likeList[0].titleId}`)}>
                        <Avatar src={thumbnailize(likeList[0].thumbnail,80)}/>
                    </IconButton>
                    :<></>}
            </div>
            <div style={style.Selected.Thumb}>
                {likeList.length>1
                    ?<IconButton style={{padding:0,margin:0}} onClick={()=>props.goto(`/detail/${likeList[1].serviceId}/${likeList[1].titleId}`)}>
                        <Avatar src={thumbnailize(likeList[1].thumbnail,80)}/>
                    </IconButton>
                    :<></>}
            </div>
            <div style={style.Selected.Thumb}>
                {likeList.length>2
                    ?<IconButton style={{padding:0,margin:0}} onClick={()=>props.goto(`/detail/${likeList[2].serviceId}/${likeList[2].titleId}`)}>
                        <Avatar src={thumbnailize(likeList[2].thumbnail,80)}/>
                    </IconButton>
                    :<></>}
            </div>
            {likeList.length>3
            ?likeList.slice(3,likeList.length).map(item =>
                <div style={style.Selected.Thumb}>
                    <IconButton style={{padding:0,margin:0}} onClick={()=>props.goto(`/detail/${item.serviceId}/${item.titleId}`)}>
                        <Avatar src={thumbnailize(item.thumbnail,80)}/>
                    </IconButton>
                </div>)
            :<></>}
        </div>
        <Swipe
            style={{marginLeft:0, marginRight:0, height: Math.min(840, props.windowSize.height * 0.6 + 100)}}
            width={props.windowSize.width} 
            onScroll={(offset)=>{
                setInternalOffset(Object.assign({},offset));
            }}
            onScrollEnd={(position, offset)=>{
                if (props.windowSize.width/4*3 > offset.x && offset.x >= props.windowSize.width/4){
                }else if (props.windowSize.width/4*3 < offset.x){
                    dislikeItem('swipe');
                }else {
                    likeItem('swipe');
                }
                setOffset({x:props.windowSize.width/2,y:0});
                setInternalOffset({x:props.windowSize.width/2,y:0});
            }}
            offset={offset}
        >
            <div style={{width: props.windowSize.width * 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <VSCard
                    {...props}
                    styleFunction={(parentWidth, parentHeight, isOpen, isActive)=>{
                        let style = FlatVSCard(parentWidth-48, parentHeight, isOpen, isActive);
                        return MergeAll(style,{transform: `rotate(${15 - 30 * internalOffset.x / (props.windowSize.width)}deg)`});
                    }}
                    item={currentItem}
                />
            </div>
        </Swipe>
        {likeList.length>=3 && !isDialogOpen
        ?<Button onClick={keepAndViewSerial} color={"primary"} variant={"contained"} style={{backgroundColor: SIGNATURE_COLOR}}>{"담아두고 정주행하러 가기"}</Button>
        :<></>}
        <div style={style.DislikeHighlight}>
            <IconButton onClick={()=>dislikeItem()}>
                <HighlightOff style={style.DislikeHighlight.Icon}/>
            </IconButton>
        </div>
        <div style={style.LikeHighlight}>
            <IconButton onClick={()=>likeItem()}>
                <CheckCircleOutline style={style.LikeHighlight.Icon}/>
            </IconButton>
        </div>
        <Dialog open={isDialogOpen} onBackdropClick={()=>setIsDialogOpen(false)}>
            <DialogTitle>{"축하합니다!"}</DialogTitle>
            <DialogContent>{"관심있는 작품을 세개 찾았어요."}<br/>{"담아두고 바로 정주행해볼까요?"}</DialogContent>
            <DialogActions>
                <Button onClick={()=>setIsDialogOpen(false)} color={"secondary"}>{"좀더 찾을래요!"}</Button>
                <Button onClick={keepAndViewSerial} color={"primary"} variant={"contained"} style={{backgroundColor: SIGNATURE_COLOR}}>{"정주행할래요!"}</Button>
            </DialogActions>
        </Dialog>
    </div>)
}
export default Match;