import React from 'react';
import './Header.scss';
import {ArrowBackIosOutlined, } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import {fn} from '../Functions';

/**
 * 
 * @param {{
 *  pageName: string,
 *  rightButton: JSX.Element,
 *  forceToShow?: boolean,
 *  hideClean?: any
 * }} props 
 */
const Header = (props) => {

    return (<div className={`SiteHeader ${props.forceToShow?'ForceToShow':''} ${props.hideClean?'HideClean':''}`}>
        {window.location.pathname !== "/"
            ?<IconButton className={"MenuButton"} onClick={()=>{
                let tokens = window.location.pathname.split('/')
                let newPath = tokens.slice(0,tokens.length-1).join('/')

                if (newPath === '/detail'){
                    fn.goto('/')
                }else{
                    fn.goto(newPath || "/")
                }
            }}><ArrowBackIosOutlined/></IconButton>
            :<>
                <img className={"Logo"} src={"https://cached-api.webtoon.today/thumb?u=https://pedia.webtoon.today/logo512.png&mw=80"} alt={"logo"} onClick={()=>fn.goto("/")}/>
                <img className={"LogoSmall"} src={"https://cached-api.webtoon.today/thumb?u=https://pedia.webtoon.today/push_96.png&mw=80"} alt={"logo"} onClick={()=>fn.goto("/")}/>
            </>}
        <div className={"PageName"}>{props.pageName || "오늘의 웹툰"}</div>
        {props.rightButton }
    </div>);

}

export default Header;