import axios from 'axios';
import ss from './Session';

// @types
// eslint-disable-next-line no-unused-vars
import {sessionType} from './Session';

/**
 * 
 * @typedef {{
 *  type:string,
 *  serviceId: string,
 *  titleId: string,
 *  detail?: string
 * }} interestFetchType
 * @typedef {{
 *  likeData: {any:boolean},
 *  subscribeData: {any:boolean},
 * }} interestType
 */

/**
 * 
 * @param {sessionType} session 
 * @param {[interestFetchType]} interest_list 
 * @param {(show: true|false) => void} setIsLoginWarningShown 
 * @param {(show: true|false) => void} setIsPushWarningShown 
 */
export async function saveInterest (interest_list, setIsLoginWarningShown) {
    if (!ss.getCurrentSession().userid || !ss.getCurrentSession().token){
        setIsLoginWarningShown(true);
        return false;
    }

    try {
        let res = await axios.put('https://api.webtoon.today/interest', {interest_list}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        return res.data.code === 200;
    }catch(e){
        console.error(e)
    }
    return false;
}

/**
 * 
 * @param {sessionType} session 
 * @param {[interestFetchType]} interest_list 
 */
export async function deleteInterest (interest_list) {
    if (!ss.getCurrentSession().userid || !ss.getCurrentSession().token){
        return false;
    }

    let res = await axios.patch('https://api.webtoon.today/interest', {interest_list}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    return res.data.code === 200;
}

/**
 * 
 * @param {sessionType} session 
 * @returns {[interestType]}
 */
export async function readInterest () {
    if (!ss.getCurrentSession().userid || !ss.getCurrentSession().token){
        return {likeData: {}, subscribeData: {}};
    }

    let res = await axios.post('https://api.webtoon.today/interest', {}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        let likeData = {}, subscribeData = {}, visitData = {};

        res.data.data.forEach(row => {
            if (row.type === 'like'){
                likeData[`${row.serviceId}:${row.titleId}`] = true;
            }else if (row.type === 'subscribe'){
                subscribeData[`${row.serviceId}:${row.titleId}`] = true;
            }else if (row.type === 'visit'){
                visitData[`${row.serviceId}:${row.titleId}`] = true;
            }
        });

        return {likeData, subscribeData, visitData};

    }else{
        return {likeData: {}, subscribeData: {}, visitData: {}};
    }

}