import React from 'react';
import VirtualRouter from './VirtualRouter';
import ss from './Data/Session';
import {getTitle, readTitles} from './Data/Title';
import {saveInterest, deleteInterest, readInterest} from './Data/Interest';
import {logBehavior,} from './Data/Behavior';
import {readFeatured} from './Data/Featured';
import {readTags} from './Data/Tag';

import {fn} from './Functions';

/**
 * @typedef {import('./Data/Session').sessionType} sessionType
 * @typedef {import('./Data/Interest').interestFetchType} interestFetchType
 * @typedef {import('./Data/Interest').interestType} interestType
 * @typedef {import('./Data/Title').titleType} titleType
 * @typedef {import('./Data/Featured').featuredType} featuredType
*/

/**
 * 
 * @typedef {{
  *   isClient: boolean, location: string,
  *   session: sessionType, sessionRefresh: ()=>Promise<void>, logout: ()=> void, GoogleSignin: (response: googleResponseType) => Promise<boolean>, FacebookSignin: (response: facebookResponseType) => Promise<boolean>,
  *   titles: titleType[], top20Tags: {tag: string, count: number}[], tags: {tag: string, count: number}[], query: string, setQuery: (newQuery: string) => void, featured: featuredType[], getTitle: (session:sessionType, serviceId: string, titleId: string) => titleType[],
  *   interests: interestType, saveAndUpdateInterest: (interest: interestFetchType[]) => Promise<void>, deleteAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
  *   isLoginWarningShown: true|false, setIsLoginWarningShown: (is: boolean) => void,
  *   isPushWarningShown: true|false, setIsPushWarningShown: (is: boolean) => void,
  *   isLoginOpen: true|false, setIsLoginOpen: (is: boolean) => void,
  *   filterIncludeAdult: boolean, setFilterIncludeAdult: (is: boolean) => void,
  *   filterOnlyToday: boolean, setFilterOnlyToday: (is: boolean) => void,
  * }} globalDataType 
  */

/**
 * 
 * @param {{isClient: boolean, location: string}} props
 * @return {JSX.Element}
 */
const App = (props) => {

    /** @type {[sessionType, (newSession:sessionType)=>void]} */
    const [session, setSession] = React.useState({});
    const {sessionRefresh, logout, GoogleSignin, FacebookSignin} = ({
        sessionRefresh: async () => {
            setSession(await ss.sessionRefresh());
        },
        logout: () => {
            ss.logout();
            setSession(ss.getCurrentSession());
        },
        GoogleSignin: async (response) => {
            await ss.GoogleSignin(response)
            if (ss.getCurrentSession().userid){
                setSession(ss.getCurrentSession());
                return true
            }else {
                return false
            };
        },
        FacebookSignin: async (response) => {
            await ss.FacebookSignin(response)
            if (ss.getCurrentSession().userid){
                setSession(ss.getCurrentSession());
                return true
            }else {
                return false
            };
        },
    });

    React.useEffect(()=>{
        (async()=>{
            await sessionRefresh();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** @type {[[titleType], ([titleType])=>void]} */
    const [titles, setTitles] = React.useState([]);
    /** @type {[string, (string)=>void]} */
    const [query, setQuery] = React.useState("");

    React.useEffect(()=>{
        fn.setQuery = setQuery;
    },[setQuery])

    React.useEffect(()=>{
        let queryString = window.location.search;
        if (!queryString || !queryString.startsWith("?")){
            return;
        }
        let queryDict = queryString.substr(1).split("&").map(pair => (([key, value]) => {let ret = {}; ret[key] = decodeURIComponent(value); return ret;})(pair.split("="))).reduce( (a,b)=> Object.assign(a,b));
        
        if (queryDict.hasOwnProperty("query")){
            setQuery(queryDict.query);
        }
    },[]);

    const [top20Tags, setTop20Tags] = React.useState([]);

    React.useEffect(()=>{
        readTitles('', 100);

        // remove un-pre-drawn dom
        let firstTag = document.getElementsByTagName('body')[0].firstChild;
        if (!firstTag.hasChildNodes()){
            document.getElementsByTagName('body')[0].removeChild(firstTag);
        }
    },[]);
    
    React.useEffect(()=>{
        (async ()=>{
            let cached_list = await readTitles('', 200,)
            if (cached_list.length > 0 ){
                setTitles(cached_list);
            }
        })();
        (async ()=>{
            let remoteTags = await readTags();
            if (remoteTags.length > 0){
                setTop20Tags(remoteTags);
            }
        })();
    },[]);

    const [tags, setTags] = React.useState([]);

    React.useEffect(()=>{
        (async ()=>{
            let remoteTags = await readTags("popular", -200, 500);
            if (remoteTags.length > 0){
                setTags(remoteTags);
            }
        })();
    },[]);

    React.useEffect(()=>{
        if (session.userid){
            logBehavior("login", {});
        }
    },[session]);
    
    const [featured, setFeatured] = React.useState([]);
    React.useEffect(()=>{
        (async ()=>{
            setFeatured(await readFeatured())
        })();
    },[]);

    /** @type {[interestType, (interestType)=>void]} */
    const [interests, setInterests] = React.useState({likeData:{}, subscribeData:{}, visitData: {}});

    React.useEffect(()=>{
        if (session.userid){
            (async () => {
                setInterests(await readInterest());
            })();
        }
    },[session]);

    const [isLoginWarningShown, setIsLoginWarningShown] = React.useState(false);
    const [isLoginOpen, setIsLoginOpen] = React.useState(false);
    const [isPushWarningShown, setIsPushWarningShown] = React.useState(false);

    /**
     * @param {interestFetchType[]} interest_list 
     * @param {(show: boolean) => void} setIsLoginWarningShown 
     * @param {(show: boolean) => void} setIsPushWarningShown 
     */
    const saveAndUpdateInterest = async (interest_list) => {
        let res = await saveInterest(interest_list, setIsLoginWarningShown);
        if (res){
            let newInterests = Object.assign({}, interests);
            for (const row of interest_list){
                newInterests[`${row.type}Data`][`${row.serviceId}:${row.titleId}`] = true;
            }
            setInterests(newInterests);
        }
    };
    /**
     * @param {[interestFetchType]} interest_list 
     * @param {(show: boolean) => void} setIsLoginWarningShown 
     * @param {(show: boolean) => void} setIsPushWarningShown 
     */
    const deleteAndUpdateInterest = async (interest_list) => {
        let res = await deleteInterest(interest_list, setIsLoginWarningShown);
        if (res){
            let newInterests = Object.assign({}, interests);
            for (const row of interest_list){
                delete newInterests[`${row.type}Data`][`${row.serviceId}:${row.titleId}`];
            }
            setInterests(newInterests);
        }
    };

    
    React.useEffect(()=>{
        let instantSequence = [];
        try {
            instantSequence = JSON.parse(localStorage.getItem('instantSequence') || '[]');
        }catch(e){
            console.error(localStorage.getItem('instantSequence'))
            console.error(e);
        }

        if (session.userid && instantSequence.length > 0){
            (async () => {
                localStorage.setItem('instantSequence', '[]')
                await saveAndUpdateInterest(
                    instantSequence.map(({serviceId, titleId}) => ({type: 'like', serviceId, titleId, detail:{} }))
                )
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[session]);

    const [filterIncludeAdult, setFilterIncludeAdult] = React.useState(JSON.parse(localStorage.getItem('config:includeAdult') || 'false'));
    const [filterOnlyToday, setFilterOnlyToday] = React.useState(JSON.parse(localStorage.getItem('config:onlyToday') || 'true'));

    React.useEffect(()=>{
        localStorage.setItem('config:includeAdult', JSON.stringify(filterIncludeAdult));
    },[filterIncludeAdult]);
    React.useEffect(()=>{
        localStorage.setItem('config:onlyToday', JSON.stringify(filterOnlyToday));
    },[filterOnlyToday]);

    window.bulkProps = window.bulkProps || {};
    
    /** @type globalDataType */
    const routingProps = {
        isClient: props.isClient, location: props.location,
        session, sessionRefresh, logout, GoogleSignin, FacebookSignin,
        titles, top20Tags, tags, query, setQuery, featured, getTitle,
        interests, saveAndUpdateInterest, deleteAndUpdateInterest,
        isLoginWarningShown, setIsLoginWarningShown,
        isPushWarningShown, setIsPushWarningShown,
        isLoginOpen, setIsLoginOpen,
        filterIncludeAdult, setFilterIncludeAdult,
        filterOnlyToday, setFilterOnlyToday,
    };

    return (
        <div className="App">
            <VirtualRouter {...routingProps} />
        </div>
    );
}

export default App;
