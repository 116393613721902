import React from 'react';
import {NaverLogo, DaumLogo, KakaoLogo, ToomicsLogo, ToptoonLogo, LezhinLogo, ComicoLogo} from '../Logos';

import ItemDetailBox from '../Component/ItemDetailBox';
import { readTitles } from '../Data/Title';

import './Service.scss';
import Header from '../Component/Header';

/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
    */
export const Service = ({serviceId, interests, filterIncludeAdult, tags}) => {

    window.canonicalPath = `/detail/${serviceId}`;

    const [titles, setTitles] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            let newTitles = await readTitles(null, 30, '1111111', serviceId, true);
            setTitles(newTitles);
        })();
    },[serviceId]);
    
    return (
        <div className={"Service"}>
            <Header
                pageName={
                    serviceId === 'naver'?'네이버 웹툰':
                    serviceId === 'daum' ?'다음 웹툰' :
                    serviceId === 'kakao'?'카카오 페이지':
                    serviceId === 'lezhin'?'레진코믹스':
                    serviceId === 'comico'?'코미코':
                    serviceId === 'toomics'?'투믹스':
                    serviceId === 'toptoon'?'탑툰':null
                }
                rightButton={<div></div>}
                hideClean={false}
            />
            <div className={"TopBack"}>
                <div className={"ThumbnailContainer"}>
                    <img className={"Thumbnail"}
                        src={
                            serviceId === 'naver'?NaverLogo:
                            serviceId === 'daum' ?DaumLogo:
                            serviceId === 'kakao'?KakaoLogo:
                            serviceId === 'lezhin'?LezhinLogo:
                            serviceId === 'comico'?ComicoLogo:
                            serviceId === 'toomics'?ToomicsLogo:
                            serviceId === 'toptoon'?ToptoonLogo
                                                    :`https://static.webtoon.today/artist/${serviceId}.png`
                        }
                        alt={serviceId}
                    />
                </div>
            </div>
            <div className={"BottomContainer"}>
                <div className={"BottomSlider"} >
                    <div className={"FeedContainer"}>
                        <div className={"GridFeed"}>
                            {titles
                                .filter(item => item.serviceId === serviceId)
                                .filter(row => !row.isAdult || filterIncludeAdult)
                                .map(item => {
                                let isLiked = item&&interests&&interests.likeData[`${item.serviceId}:${item.titleId}`]?true:false;
                                let isSubscribed = item&&interests&&interests.subscribeData[`${item.serviceId}:${item.titleId}`]?true:false;
                                return (
                                    <ItemDetailBox
                                        key={`${item.serviceId}:${item.titleId}`}
                                        isLiked={isLiked}
                                        isSubscribed={isSubscribed}
                                        item={item}
                                        tags={tags}
                                    />);
                            })}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;