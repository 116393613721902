import React from 'react';

import ExpandableGrid from '../Component/ExpandableGrid';
import {Main as MainStyle} from '../Styles';
import { Button, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { getOffsetInScreen, MergeAll, randomString } from '../Functions';
import { getTitles, readTitles } from '../Data/Title';
import Swipe from '../Component/Swipe';

/** @typedef {import('../VirtualRouter').point} point */

/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
    */
export const Main = (props) => {

    window.canonicalPath = '/';

    const [uniqueId, ] = React.useState(randomString(16));

    const [currentTag, setCurrentTag] = React.useState('오늘_업데이트');
    React.useEffect(()=>{
        if (props.top20Tags.map(row => row.tag).indexOf(currentTag) < 0 && ['오늘_업데이트', '구독중'].indexOf(currentTag) < 0){
            setCurrentTag('오늘_업데이트');
        }
    },[currentTag, props.top20Tags]);

    const style = MainStyle(props.windowSize.width, props.windowSize.height,);

    const [maxLength, setMaxLength] = React.useState(0);
    React.useEffect(()=>{
        if (document.body.scrollHeight - props.windowSize.height - 200 < props.windowScrollY){
            if (props.top20Tags.length > maxLength){
                setMaxLength(maxLength+2);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.windowSize.height, props.windowScrollY, props.top20Tags,]);
    const [maxTodayLength, setMaxTodayLength] = React.useState(3);
    const [maxSubsLength, setMaxSubsLength] = React.useState(3);

    const [subscribes, setSubscribes] = React.useState([]);
    React.useEffect(()=>{
        if (Object.keys(props.interests.subscribeData).length === 0){
            setSubscribes([]);
            return;
        }
        (async()=>{
            setSubscribes(await getTitles(Object.keys(props.interests.subscribeData).map(pair => pair.split(":"))));
        })();
    },[props.session, props.interests]);

    const [topicTitles, setTopicTitles] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            if (topicTitles.length < maxLength){
                if (Object.keys(props.top20Tags).length === 0){
                    return;
                }
                let newTopicTitles = [...topicTitles, [props.top20Tags[topicTitles.length].tag, await readTitles(props.top20Tags[topicTitles.length].tag, 10, 'tags')]];
                setTopicTitles(newTopicTitles);
                sessionStorage.setItem("feed:topicTitles", JSON.stringify(newTopicTitles));
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[maxLength, topicTitles.length]);

    const [tagOffset, setTagOffset] = React.useState(0);
    React.useEffect(()=>{
        let topNearestTopic = "NA";
        let distFromTop = 1024;
        ["오늘_업데이트", "구독중"].concat(props.top20Tags.map(row => row.tag)).forEach(tag =>{
            let tagDistFromTop = getOffsetInScreen(document.getElementById(tag)).top;
            if (distFromTop >= tagDistFromTop && tagDistFromTop >= 0){
                topNearestTopic = tag;
                distFromTop = tagDistFromTop;
            }
        })
        if (topNearestTopic !== "NA"){
            setCurrentTag(topNearestTopic);
            setTagOffset(document.getElementById(`${topNearestTopic}_shortcut`).offsetLeft);
        }

    },[props.top20Tags, props.windowScrollY]);

    return (
        <div id={uniqueId} style={style}>
            <div style={style.TagContainer}>
                <Swipe
                    offset={{x:tagOffset}}
                    width={props.windowSize.width}    
                >
                    <div style={style.TagContainer.TagSlider}>
                    {[{tag:'오늘_업데이트',}, ...(subscribes.length>0?[{tag: '구독중'}]:[])].concat(props.top20Tags).map(({tag,})=> 
                        <Button
                            key={`${tag}_shortcut`}
                            id={`${tag}_shortcut`}
                            onClick={()=>{
                                if (props.top20Tags.map(tagCount => tagCount.tag).indexOf(tag) >= maxLength){
                                    setMaxLength(props.top20Tags.map(tagCount => tagCount.tag).indexOf(tag)+1);
                                    const waitForHashExists = setInterval(()=>{
                                        if (!document.getElementById(uniqueId)){
                                            clearInterval(waitForHashExists);
                                            return;
                                        }
                                        if (!document.getElementById(tag)){
                                            return;
                                        }
                                        window.location.hash=`#${tag}`;
                                        setCurrentTag(tag);
                                        clearInterval(waitForHashExists);
                                    },20);
                                }else{
                                    window.location.hash=`#${tag}`;
                                    setCurrentTag(tag);
                                }
                            }}
                            style={MergeAll(style.TagContainer.TagSlider.Tag,tag === currentTag?{color: 'black'}:{})}
                        >
                            {`#${tag}`}
                        </Button>
                    )}
                    </div>
                </Swipe>
            </div>
            <div style={style.TagContainer.TagSlider.Tag} id={"오늘_업데이트"}>{"#오늘_업데이트"}</div>
            
            <Swipe
                width={props.windowSize.width-64}
                style={{paddingLeft:24}}
                onScroll={(offset, fullWidth) => {
                    if (offset.x > fullWidth - 300 - (props.windowSize.width-64)){
                        setMaxTodayLength(maxTodayLength+10);
                    }
                }}
            >
                <div style={style.FeedContainer}>
                    <div style={style.FeedContainer.GridFeed}>
                        {props.titles
                            .slice(0,maxTodayLength)
                            .filter(row => !row.isAdult || props.filterIncludeAdult)
                            .filter(row => row.lastEpisodeUpdatedAt >= (new Date().getTime() / 1000 - 86400 ) || !props.filterOnlyToday )
                            .map(item => {
                            let isLiked = item&&props.interests&&props.interests.likeData[`${item.serviceId}:${item.titleId}`]?true:false;
                            let isSubscribed = item&&props.interests&&props.interests.subscribeData[`${item.serviceId}:${item.titleId}`]?true:false;
                            return (
                                <ExpandableGrid
                                    key={`${item.serviceId}:${item.titleId}`}
                                    {...props}
                                    isLiked={isLiked}
                                    isSubscribed={isSubscribed}
                                    item={item}
                                />);
                        })}
                        
                    </div>
                </div>
            </Swipe>
            {subscribes.length > 0
            ?<>
            <div style={style.TagContainer.TagSlider.Tag} id={"구독중"}>{"#구독중"}</div>
            <Swipe
                width={props.windowSize.width-64}
                style={{paddingLeft:24}}
                onScroll={(offset, fullWidth) => {
                    if (offset.x > fullWidth - 300 - (props.windowSize.width-64)){
                        setMaxSubsLength(maxTodayLength+3);
                    }
                }}
            >
                <div style={style.FeedContainer}>
                    <div style={style.FeedContainer.GridFeed}>
                        {subscribes
                            .slice(0,maxSubsLength)
                            .filter(row => !row.isAdult || props.filterIncludeAdult)
                            .map(item => {
                            let isLiked = item&&props.interests&&props.interests.likeData[`${item.serviceId}:${item.titleId}`]?true:false;
                            let isSubscribed = item&&props.interests&&props.interests.subscribeData[`${item.serviceId}:${item.titleId}`]?true:false;
                            return (
                                <ExpandableGrid
                                    key={`${item.serviceId}:${item.titleId}`}
                                    {...props}
                                    isLiked={isLiked}
                                    isSubscribed={isSubscribed}
                                    item={item}
                                />);
                        })}
                        
                    </div>
                </div>
            </Swipe>
            </>
            :<></>}
            {topicTitles.slice(0,maxLength).map( ([tag, list],index) =>
            <div key={tag}>
                <div style={style.TagContainer.TagSlider.Tag} id={tag}>{`#${tag}`}</div>
                <Swipe
                    width={props.windowSize.width-64}
                    style={{paddingLeft:24}}
                >
                    <div style={style.FeedContainer}>
                        <div style={style.FeedContainer.GridFeed}>
                            {list
                                .filter(row => !row.isAdult || props.filterIncludeAdult)
                                .map(item => {
                                let isLiked = item&&props.interests&&props.interests.likeData[`${item.serviceId}:${item.titleId}`]?true:false;
                                let isSubscribed = item&&props.interests&&props.interests.subscribeData[`${item.serviceId}:${item.titleId}`]?true:false;
                                return (
                                    <ExpandableGrid
                                        key={`${item.serviceId}:${item.titleId}`}
                                        {...props}
                                        isLiked={isLiked}
                                        isSubscribed={isSubscribed}
                                        item={item}
                                        recommOrEpisodeTitle={"recomm"}
                                    />);
                            })}
                        </div>
                    </div>
                </Swipe>
            </div>
            )}
            {props.top20Tags.length>maxLength
            ?<Button fullWidth onClick={()=>{setMaxLength(Math.min(props.top20Tags.length, maxLength+2))}}>{"더보기"}</Button>
            :<></>}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {"더 재미있는 웹툰을 찾는다면"}
                <IconButton onClick={()=>{props.goto('/match')}}>
                    <Search/>
                </IconButton>
            </div>
            <div style={{textAlign:'center'}}><Button onClick={()=>props.goto("/privacypolicy")}>{"privacy policy"}</Button></div>
        </div>
    );
}

export default Main;