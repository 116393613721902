import React from 'react';
import { TextField } from '@material-ui/core';

const EditableDateField = (props) => {
    return (props.isEditing
    ?(<TextField
        type={props.fullDate
                ?"datetime-local"
                :props.timeOnly
                    ?"time"
                    :props.monthOnly
                        ?"month"
                        :"date"}
        defaultValue={props.defaultValue
                        ?props.fullDate
                            ?new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substr(0,"2020-08-17T12:00:00".length)
                            :props.timeOnly
                                ?new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substr(11,5)
                                :props.monthOnly
                                    ?new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substr(0,"2020-08".length)
                                    :new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substr(0,10)
                        :undefined}
        onBlur={(event)=>{
            let newData = {}; newData[props.field] = new Date(event.target.value).getTime()/1000;
            props.update(newData);
        }}

        inputProps={props.timeOnly?{step:3600}:{}}
    />)
    :<div style={props.style}>{props.defaultValue
        ?props.fullDate
            ?new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substr(0,"2020-08-17T12:00:00".length)
            :props.timeOnly
                ?new Date(props.defaultValue * 1000).toLocaleTimeString()
                :new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substr(0,"2020-08-17".length)
    :""}</div>);
}

export default EditableDateField;