import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import firebase from 'firebase/app';
import 'firebase/firebase-messaging';
import 'firebase/firebase-analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCFgroUfuSBB8-0zWQGtAqgl0_qDCXf-xc",
    authDomain: "webtoon-today-284708.firebaseapp.com",
    databaseURL: "https://webtoon-today-284708.firebaseio.com",
    projectId: "webtoon-today-284708",
    storageBucket: "webtoon-today-284708.appspot.com",
    messagingSenderId: "1093253062710",
    appId: "1:1093253062710:web:fb1d300560890a49bd3897",
    measurementId: "G-VSL0CTMT6L"
};

firebase.initializeApp(firebaseConfig);
window.firebase = firebase;

if (['127.0.0.1', 'localhost:3000', 'mydomain.com:3000', 'localhost:3001', 'mydomain.com:3001', 'd26pjf3a18hvh4.cloudfront.net'].indexOf(window.location.host) >= 0){
    //skip logging for dev version
}else{
    firebase.analytics();

    if (window.gtag){
        window.gtag('config', 'AW-604780621');
        window.gtag('config', 'G-CW2KPW3PQK');
      
    }
}

try{
    if (window.firebase){
        window.fcm = window.firebase.messaging();
        window.fcm.usePublicVapidKey("BD5ySlAkCfv1r61ZM6OG3yyBa75Aj8xvjJueKIX8Y_gLn6cnLClLDrxr0cxRE0rE7nCo_1CLn4IFBfT_2fMlkq4");
    }else{
        console.log("no firebase");
    }
}catch(e){
    console.error(e);
}


if (window.fcm){
    window.fcm.onMessage((payload) => {
        
        console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
        // Customize notification here
        //const payload = event.data.json();
        const title = payload.notification.title;
        const options = {
            body: payload.notification.body,
            icon: payload.notification.icon,
            data: payload.notification.click_action,
            tag: payload.notification.title,
        };

        console.log(title, options)

        //return self.registration.showNotification(title, options);
    })
}

ReactDOM.render(
    <App isClient={true} />,
    document.getElementById('root'),
    ()=>{
      // remove un-pre-drawn dom
      let firstTag = document.getElementsByTagName('body')[0].firstChild;
      if (!firstTag.hasChildNodes()){
          document.getElementsByTagName('body')[0].removeChild(firstTag);
      }
      document.documentElement.dataset.scroll = 0;
      document.documentElement.dataset.scrollRemainder = document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
      document.addEventListener('scroll', () => {
        document.documentElement.dataset.scroll = window.scrollY;
  
        document.documentElement.dataset.scrollRemainder = document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
        
      });
    }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
