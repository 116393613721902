import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

/**
 * 
 * @param {{
 *  isEditing: true|false,
 *  defaultValue: any,
 *  field: string,
 *  update: () => void,
 *  options: [{key: string, value: string}],
 * }} props 
 */
const EditableSelectField = (props) => {
    
    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue);
    },[props.defaultValue]);

    return (props.isEditing
    ?(<Select
        defaultValue={value || undefined}
        value={value}
        onChange={(event)=> {
            setValue(event.target.value);
            let newData = {}; newData[props.field] = event.target.value;
            props.update(newData);
        }}
    >
        {props.options.filter(row => row.value !== "header" && (!row.expose || row.expose === 1)).map(row => (
            <MenuItem key={row.key} value={row.value}>{row.key}</MenuItem>
        ))}
    </Select>)
    :<div style={props.style}>{((arr)=> (
        arr.length>0
            ?arr[0].key
            :"")
        )(props.options.filter(row => row.value === props.defaultValue))}</div>);
}

export default EditableSelectField;