import axios from 'axios';
import ss from './Session';

// @type
// eslint-disable-next-line no-unused-vars
/** @typedef {import('./Session').sessionType} sessionType */

let memStore = {};

/**
 * 
 * @typedef {{
 *     serviceId: string, titleId: string, title: string, thumbnail: string, tags: string, isAdult: boolean,
 *     lastUpdatedAt: number, lastEpisodeId: string, lastFreeEpisodeId: string, firstEpisodeId: string, episodeCount: number, freeEpisodeCount: number,
 *     lastEpisodeTitle: string, lastEpisodeHref: string, lastEpisodeThumbnail: string,
 *     lastFreeEpisodeTitle: string, lastFreeEpisodeHref: string,
 *     firstEpisodeTitle: string, firstEpisodeHref: string,
 *     href: string,
 *     rating?: number, popularity?: number, rank?: number,
 *     is_finished: 0|1, weekday: string,
 * }} titleType 
 */

/**
 * 
 * @param {string} serviceId 
 * @param {string} titleId 
 * @returns {Promise<titleType[]>}
 */
export async function getTitle (serviceId, titleId) {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/title', {key: [serviceId, titleId].join(":")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {string} serviceId 
 * @param {string} titleId 
 * @returns {Promise<titleType[]>}
 */
export async function getTitles (keys) {
    
    if (memStore[`titles?`+JSON.stringify({keys})]){
        return memStore[`titles?`+JSON.stringify({keys})];
    }

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/title', {key: keys.map(pair => pair.join(":")).join(",")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            memStore[`titles?`+JSON.stringify({keys})] = res.data.data;
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {string} query 
 * @param {number} limit 
 * @returns {Promise<titleType[]>}
 */
export async function readTitles (query, limit, type, weekday, serviceId, includeAll) {

    if (memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})]){
        return memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})];
    }

    try{
        let keys = await axios.get(`https://challenge-api.webtoon.today/search?limit=${limit}${query?`&query=${query}`:``}${type?`&type=${type}`:``}${weekday?`&weekday=${weekday}`:``}${serviceId?`&serviceId=${serviceId}`:``}${includeAll?`&includeAll=true`:``}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (keys.data && keys.data.code === 200){
            
            if (keys.data.data.length === 0){
                return [];
            }

            let res = await axios.post(`https://challenge-api.webtoon.today/title`, {key: keys.data.data.map(pair => `${pair.serviceId}:${pair.titleId}`).join(',')}, {
                headers: {
                    Authorization: `Bearer ${ss.getCurrentSession().jwt}`
                },
                withCredentials: true
            });

            if (res.data && res.data.code === 200){

                memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})] = res.data.data;
                return res.data.data;
    
            }else {
                return [];
            }
        }else{
            return [];
        }
    }catch(e){
        console.error(e);
        return [];
    }
}