import React from 'react';

import { Button, IconButton, Switch } from '@material-ui/core';
import {Add, Email, Notifications} from '@material-ui/icons';
import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginButton, } from "react-social-login-buttons";

import {getTitles} from '../Data/Title';
import {askPushPermission, hasPushPermission} from '../Data/Push';

import InstaGrid from '../Component/InstaGrid';
import CollapseList from '../Component/CollapseList';
import ProfileComponent from '../Component/Profile';

import { MergeAll } from '../Functions';
import {Setting as SettingStyle} from '../Styles';
import { logBehavior } from '../Data/Behavior';

const GOOGLE_CID=window.location.host.endsWith("webtoon.today")
    ?"1093253062710-dh2542i4vf7soiqjji199vcr0i02d7vh.apps.googleusercontent.com"
    :"824057779622-lf4u5l68msk11noirpnobfmlrljamicc.apps.googleusercontent.com";

const FACEBOOK_CID=window.location.host.endsWith("webtoon.today")
    ?"619059982083533"
    :"685998568617743";

/**
 * 
 * @param {{
 *  filterIncludeAdult: bool, setFilterIncludeAdult: (bool)=>void,
 * filterOnlyToday: bool, setFilterOnlyToday: (bool)=>void,
 * isPushWarningShown: bool, setIsPushWarningShown: (bool)=>void,
 * isLoginWarningShown: bool, setIsLoginWarningShown: (bool)=>void,
 * style: React.CSSProperties
 * }} props 
 */
const CommonSetting = ({filterIncludeAdult, setFilterIncludeAdult, filterOnlyToday, setFilterOnlyToday, isPushWarningShown, setIsPushWarningShown, isLoginWarningShown, setIsLoginWarningShown, style, session, goto}) => {
    return ( <div style={style}>
        <div style={style.Row}>
            {"성인작품 포함"}
            <Switch checked={filterIncludeAdult} onChange={(event, newValue)=>{setFilterIncludeAdult(newValue)}}/>
        </div>
        <div style={style.Row}>
            {"오늘 업로드만 보기"}
            <Switch checked={filterOnlyToday} onChange={(event, newValue)=>{setFilterOnlyToday(newValue)}}/>
        </div>
        <div style={style.Row}>
            {"신작 추천 알림"}
            <Switch value={false}/>
        </div>
        <div style={style.Row}>
            {"구독 업데이트 알림"}
            <IconButton
                onClick={()=>{
                    if (hasPushPermission(session)){
                        return;
                    }
                    if (!session.userid){
                        setIsLoginWarningShown(true);
                        return;
                    }
                    askPushPermission(session, setIsPushWarningShown);
                }}
            >
                <Notifications color={hasPushPermission(session)?"secondary":"action"}/>
            </IconButton>
        </div>
        <div style={style.Row}>
            {"문의하기"}
            <IconButton onClick={()=>goto('mailto:help@webtoon.today?subject=오늘의 웹툰 사용문의')}>
                <Email/>
            </IconButton>
        </div>
    </div>);
}

const FAQ = ({style, goto}) => {
    return (<>
        <div style={style.Header}>{"서비스에 대해서"}</div>
        <div style={style.Body}>
            <CollapseList
                title={"오늘의 웹툰은 어떤 서비스인가요?"}
                body={"네이버, 다음, 카카오, 레진, 투믹스, 탑툰, 코미코, ... 사이트는 많은데 어디에 뭐가 있는지, 어떤게 내취향인지 몰라서 불편하셨죠?\n오늘의 웹툰은 주요 7개 사이트의 2,700 여 개 웹툰을 모아서 보여주고, 취향에 맞춰 추천하고, 업데이트 알림을 제공합니다."}
            />
            <CollapseList
                title={"무료인가요? 유료 웹툰도 무료로 볼 수 있나요?"}
                body={"오늘의 웹툰은 만화 해적사이트가 아닙니다! 각각의 웹툰을 열람하실 때에는 해당 플랫폼의 정책에 따르게 됩니다.\n유료 결제가 필요한 작품은 해당 서비스에서 결제 후 이용할 수 있습니다."}
            />
            <CollapseList
                title={"회원가입 해야 사용할 수 있나요?"}
                body={"오늘의 웹툰에 회원가입 하지 않으셔도 모든 기능을 이용할 수 있습니다. 각각의 웹툰을 열람하실 때에는 해당 플랫폼에서 로그인을 요구할 수 있습니다."}
            />
            <CollapseList
                title={"성인 웹툰은 어떻게 찾을 수 있나요?"}
                body={"설정 중 '성인웹툰 포함' 항목을 켜주시기 바랍니다."}
            />
        </div>
        <div style={style.Header}>{"회원정보에 대해서"}</div>
        <div style={style.Body}>
            <CollapseList
                title={<>{"이 앱을 사용하면 어떤 정보를 수집하나요?"}<br/>{"왜 수집하나요?"}</>}
                body={<><Button onClick={()=>goto('/privacypolicy/kr')} variant={"outlined"} style={{textAlign: 'left', padding:0, fontSize: '0.7rem'}}>{"개인정보 처리 방침(한국어)"}</Button>{"를 참고하시기 바랍니다."}</>}
            />
        </div>
    </>);
}

/**
 * @typedef {import('../VirtualRouter').rountingDataType} rountingDataType
 * @param {rountingDataType} props
 */
const Setting = (props) => {
    window.canonicalPath = '/setting';

    let style = SettingStyle(props.windowSize.width, props.windowSize.height);

    const [currentInterest, setCurrentInterest] = React.useState('subscribe');
    const [currentList, setCurrentList]  = React.useState([]);
    const [maxNum , setMaxNum] = React.useState(10);

    React.useEffect(()=>{
        if (!props.session || !props.session.name || Object.keys(props.interests[`${currentInterest}Data`]).length === 0){
            setCurrentList([]);
            return;
        }
        (async ()=>{
            setCurrentList(await getTitles(Object.keys(props.interests[`${currentInterest}Data`]).map(key=> key.split(":"))));
        })();
    },[currentInterest, props.interests, props.session]);

    if (!props.session || !props.session.name){
        return (<div style={style}>
            <div style={style.GradientBackground}>
            </div>
            <div className={"settingContainer"} style={style.SettingContainer}>
                <div style={style.SettingContainer.Section}>
                    <div style={style.SettingContainer.Section.SectionHeader}>{"로그인"}</div>
                    <div style={style.SettingContainer.Section.SectionBody}>
                        <div style={{paddingTop:100}}></div>
                        <img style={style.SettingContainer.Section.SectionBody.Profile.Image} src='/logo512.png' alt={"webtoon today logo"}/>
                        <div style={{paddingTop:100}}></div>
                        <GoogleLogin
                            clientId={GOOGLE_CID}
                            redirectUri="/"
                            buttonText="SIGN IN WITH GOOGLE"
                            theme={"light"}
                            onSuccess={async (response) => {
                                let res = await props.GoogleSignin(response);
                                if (res) {
                                    props.setIsLoginOpen(false)}
                                    logBehavior('loginSuccess',)
                                }
                            }
                            onFailure={async (response) => {
                                let res = await props.GoogleSignin(response);
                                if (res) {
                                    props.setIsLoginOpen(false)
                                }
                                logBehavior('loginFailed',)
                            }}
                            cookiePolicy={'single_host_origin'}
                        />
                        <FacebookLogin
                            appId={FACEBOOK_CID}
                            autoLoad={false}
                            fields={"name,email,picture"}
                            redirectUri={window.location.href}
                            callback={async (response) => {
                                let res = props.FacebookSignin(response);
                                if (res) {
                                    props.setIsLoginOpen(false)
                                    logBehavior('loginSuccess',)
                                }
                            }}
                            render={renderProps => (
                            <FacebookLoginButton
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                style={{height: 40, marginLeft: 0, fontSize: 16, width: 211, whietSpace: 'nowrap'}}
                            />
                            )}
                        />
                    </div>
                </div>                
                <div style={style.SettingContainer.Section}>
                    <div style={style.SettingContainer.Section.SectionHeader}>{"환경 설정"}</div>
                    <CommonSetting {...props} style={style.SettingContainer.Section.SectionBody.CommonSetting}/>
                </div>
                <div style={style.SettingContainer.Section}>
                    <div style={style.SettingContainer.Section.SectionHeader}>{"자주 묻는 질문"}</div>
                    <FAQ style={style.SettingContainer.Section.SectionBody.FAQ} goto={props.goto}/>
                </div>
            </div>
        </div>);
    }else{
        return (<div style={style}>
            <div className={"settingContainer"} style={style.SettingContainer}>
                <div style={style.SettingContainer.Section}>
                    <div style={style.SettingContainer.Section.SectionHeader}>{"프로필"}</div>
                    <div style={style.SettingContainer.Section.SectionBody}>
                        {(()=>{let Profile = style.SettingContainer.Section.SectionBody.Profile; return <>
                        <ProfileComponent
                            session={props.session}
                            sessionRefresh={props.sessionRefresh}
                            givenProfile={{
                                addressid: null,
                                ...(props.session)
                            }}
                            windowSize={props.windowSize}
                        />
                        <div style={Profile.InterestButtons}>
                            <Button
                                style={MergeAll(Profile.InterestButtons.Button,currentInterest === 'like'?{fontSize: '1rem', fontWeight: 'bold'}:{})}
                                onClick={()=>{setCurrentInterest('like')}}
                            >
                                {`좋아하는 ${Object.keys(props.interests.likeData).length}`}
                            </Button>
                            <div style={Profile.InterestButtons.Divider}/>
                            <Button
                                style={MergeAll(Profile.InterestButtons.Button,currentInterest === 'subscribe'?{fontSize: '1rem', fontWeight: 'bold'}:{})}
                                onClick={()=>{setCurrentInterest('subscribe')}}
                            >
                                {`구독 중인 ${Object.keys(props.interests.subscribeData).length}`}
                            </Button>
                            <div style={Profile.InterestButtons.Divider}/>
                            <Button
                                style={MergeAll(Profile.InterestButtons.Button,currentInterest === 'visit'?{fontSize: '1rem', fontWeight: 'bold'}:{})}
                                onClick={()=>{setCurrentInterest('visit')}}
                            >
                                {`읽고 있는 ${Object.keys(props.interests.visitData).length}`}
                            </Button>
                        </div>
                        <div style={Profile.Grid}>
                            {currentList.slice(0, maxNum).map(title => {
                                return <InstaGrid key={`${title.serviceId}:${title.titleId}`}
                                    {...(MergeAll(props,{item:title, width: Profile.Grid.GridUnit.width, marginRatio: 4/Profile.Grid.GridUnit.width }))}
                                />
                            })}
                        </div>
                        {currentList.length > maxNum
                            ?<Button
                                variant={"contained"} style={Profile.ShowMoreButton} fullWidth
                                onClick={()=>{setMaxNum(maxNum + 10)}}
                            >
                                <Add/>{"펼치기"}
                            </Button>
                            :<Button
                                variant={"contained"} style={Profile.HideButton} fullWidth
                                onClick={()=>{setMaxNum(10)}}
                            >
                                {"닫기"}
                            </Button>}
                            <Button
                                variant={"outlined"} style={Profile.LogoutButton} color={"secondary"} fullWidth
                                onClick={()=>{props.logout()}}
                            >
                                {"로그아웃"}
                            </Button>
                        </>})()}
                    </div>
                </div>                
                <div style={style.SettingContainer.Section}>
                    <div style={style.SettingContainer.Section.SectionHeader}>{"환경 설정"}</div>
                    <CommonSetting {...props} style={style.SettingContainer.Section.SectionBody.CommonSetting}/>
                </div>
                <div style={style.SettingContainer.Section}>
                    <div style={style.SettingContainer.Section.SectionHeader}>{"자주 묻는 질문"}</div>
                    <FAQ style={style.SettingContainer.Section.SectionBody.FAQ} goto={props.goto}/>
                </div>
            </div>
        </div>);
    }

};

export default Setting;