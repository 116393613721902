import axios from 'axios';
import ss from './Session';

export const listMetas = async (at, year, month) => {
    let ret = [];

    let req = { at, year, month };
    let res = await axios.post('https://api.webtoon.today/meta', req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        ret = res.data.data;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
};

export const updateMeta = async (row) => {
    let ret = null;

    let req = Object.assign({}, row);
    let res = await axios.patch('https://api.webtoon.today/meta', req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return row.id;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
};

export const createMeta = async (row) => {
    let ret = null;

    let req = row;
    if (row instanceof Array){
        req = {records: row};
    }
    let res = await axios.put('https://api.webtoon.today/meta', req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
};