import { Button, Card, IconButton } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import React from 'react';
import { MergeAll } from '../Functions';

/**
 * @typedef {import('../VirtualRouter').size} size
 * 
 * @param {{
 *   isOpen: boolean,
 *   message: JSX.Element,
 *   windowSize: size,
 *   positionY: number,
 *   animationType: 'up'|'down'|'leftToRight'|'rightToLeft',
 *   onCllick: (event: Event)=>void,
 *   onClose: (event: Event)=>void,
 * }} props
 */
const Toast = ({isOpen, message, onClick, onClose, windowSize, animationType}) => {

    return (
    <Card
        style={
            MergeAll({
                width: windowSize.width - 20,
                borderRadius: 10,
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: '400ms',
                backgroundColor: '#EEEEEE',
                zIndex: 1,
            },
            animationType === 'up'             ?{left: 10, rigtht: 10, bottom: isOpen? 60: -100}:
            animationType === 'down'           ?{left: 10, rigtht: 10, top:    isOpen? 10: -100}:
            animationType === 'leftToRight'    ?{top: 10, left:   isOpen? 10: -windowSize.width}:
            /*animationType === 'rightToLeft'?*/{top: 10, right:  isOpen? 10: -windowSize.width})
        }
    >
        <Button
            onClick={onClick}
        >
            {message}
        </Button>
        <IconButton style={{float:'right'}} onClick={onClose}>
            <HighlightOff/>
        </IconButton>
    </Card>);
}

export default Toast;