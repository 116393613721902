import {MergeAll, pixelize, gradient} from './Functions';

export const SIGNATURE_COLOR = '#00B0F0';

const GRID_SIZE=100;
const GRID_MARGIN_RATIO=0.1;
export const ROUNDED_SHADOWED_BOX = {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    borderRadius: GRID_SIZE*GRID_MARGIN_RATIO*2,
    marginTop: GRID_SIZE*GRID_MARGIN_RATIO, marginBottom: GRID_SIZE*GRID_MARGIN_RATIO,
    marginLeft: GRID_SIZE*GRID_MARGIN_RATIO, marginRight: GRID_SIZE*GRID_MARGIN_RATIO,
};
export const ROUNDED_SHADOWED_FLOATED_BOX = {
    boxShadow: '0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)',
    borderRadius: GRID_SIZE*GRID_MARGIN_RATIO*2,
};
export const NOROUND_NOSHADOW_BOX = {
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    borderRadius: GRID_SIZE*GRID_MARGIN_RATIO/2,
};
export const NOROUND_NOSHADOW_FLOATED_BOX = {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22)',
    borderRadius: GRID_SIZE*GRID_MARGIN_RATIO/2,
};
const CARD_HEIGHT=300;

/**
 * 
 * @param {number} parentWidth 
 * @param {number} parentHeight 
 * @param {number} scrollY 
 */
export const Detail = (width) => {
    const isNarrow = width < 420;

    //let scrollHeight = 160 + (pixelize(4) + 10) * 3 + 300 * 3 + (pixelize(3) + 20);
    //let progress = (scrollY / (scrollHeight));
    return {
    /* 0 <= scrollY <= calc(8rem + 80px) */
    width: isNarrow?'100%':420,
    left: isNarrow?0:((width - 420) / 2),
    height: '100%',
    overflowY: 'hidden',
    position: 'relative',
    Header: {
        TopButtonContainer: {
            position: 'absolute',
            top: 0, left: 0,
            display: 'flex',
            width: '100%',
            BackButton: {
                textAlign: 'center',
                paddingTop: 10,
                paddingLeft: 10,
                
            },
            
        },
        Title: {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            height: '2.8rem',
            padding: 10,
            fontSize: '1.2rem',
            Avatar: {height: '2rem', width: '2rem', margin: '0.4rem'},
        },
        Description: {
            fontSize: '1rem',
            lineHeight: '1.4rem',
            fontStyle: 'italic',
            wordBreak: 'keep-all',
            marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem',
            textAlign: 'center',
        },        
        Detail: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow:1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginLeft: 2,
            marginRight: 2,
            height: 60,
            MetaRow: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow:1,
                justifyContent: 'space-evenly',
                alignItems: 'center',
                HeaderCell: {
                    width: 50,
                    height: 20,
                    fontSize: '0.6rem',
                    paddingLeft: 5, paddingRight: 5,
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    lineHeight: '20px',
                },
                RecommHeaderCell: {
                    width: 50,
                    height: 20,
                    fontSize: '0.6rem',
                    paddingLeft: 5, paddingRight: 5,
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    lineHeight: '20px',
                },
                BodyCell: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 50,
                    height: 40,
                    fontSize: '1rem',
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: 'center',
                    Inner: {maxWidth: '100%', maxHeight: '100%'},
                }
            },
        },
        ThumbnailContainer: {
            width: '100%',
            height: (isNarrow?'100%':420) / 16*9,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 0,
            overflowY: 'hidden',
            borderRadius: 10,
            display: 'flex',
            transition: '200ms',
            Thumbnail: {
                flexShrink: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                flexGrow: 0,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                Inner: {
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderRadius: 10,
                    zIndex: 1,
                }
            },
        },
        Tags: {
            marginLeft: 12,
            marignRight: 12,
            Chip: {padding: 0, margin: 5, backgroundColor: "white"},
        },        
        ReviewInShort: {
            padding: 10,
            Header: {
                
            }
        },
    },
    Buttons: {
        position: 'relative',
        width: '100%', display: 'inline-block',
        backgroundColor: 'white',
        wordBreak: 'keep-all',
        whiteSpace: 'nowrap',
        Child: {
            float: 'left',
            width: '33.333333%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 0,
            Title: {
                paddingLeft: 5,
                paddingBottom: 6,
            }
        }
    },
    Episodes: {
        display: 'flex',
        flexDirection: 'column',
        Episode: MergeAll({
            display: 'flex',
            position: 'relative',
            paddingRight: 20,
            margin: 0,
            height: '6rem',
            textAlign:'left',
            justifyContent: 'left',
            alignItems: 'left',
            borderBottom: '1px lightgray solid',
            borderRadius: 0,
            Thumbnail: {
                height: '5.2rem', padding: '0.4rem',
            },
            TextContainer:{
                height: '5rem',
                Title: {
                    fontSize: '0.8rem',
                },
                SubTitle: {
                    fontSize: '0.7rem',
                    color: 'rgba(51,51,51,0.4)',
                },
            },
            Tag: {
                position: 'absolute',
                bottom: 5,
                right: 20,
                fontSize: '0.7rem'
            }
        }),
    },
    Recomm: {
        Header: {fontSize: '1.2rem', paddingLeft: 10, paddingRight: 10, paddingTop: 30, paddingBottom: 10, wordBreak: 'keep-all',},
        Body: {
            display: 'flex',
            paddingRight: width / 4,
            GridFeed: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                flexShrink: 0,
            },
        }
    },
    Review: {
        paddingBottom: 40
    },
}};

/**
 * 
 * @param {number} parentWidth 
 * @param {number} parentHeight 
 * @param {number} scrollY 
 */
export const Service = (width, height, scrollY) => {
    let scrollHeight = 160 + pixelize(12);
    let progress = (scrollY / (scrollHeight));
    return {
    /* 0 <= scrollY <= calc(8rem + 80px) */
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    position: 'relative',
    TopBack: {
        top: 0, left: 0, paddingTop: '4rem',
        position: 'fixed',
        width: '100%',
        /* TopBack's height will goes to 60% from 100%, as scroll goes to calc(8rem + 80px) */
        height: `${ gradient(progress, 100, 60) }%`,
        Title: {
            fontSize: '2em',
            color: 'white',
            Avatar: {float:'left', height: '2rem', width: '2rem', margin: '0.4rem'},
        },
        ThumbnailContainer: {
            position: 'absolute',
            top: 0, left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 0,
            Thumbnail: {
                flexGrow: 0,
                width: 200,
            },
        },
        Background: {
            position: 'absolute',
            top: 0, left: 0,
            width: '100%',
            height: '150%',
            zIndex: -1,
            objectFit: 'cover',
            filter: "blur(2px) brightness(60%)",
        },
    },
    BottomContainer: {
        top: 0, left: 0,
        position: 'fixed',
        width: '100%',
        height: height,
        overflow: 'auto',
        BottomSlider: {
            position: 'relative',
            width:  width<640?width:Math.max(640, Math.floor(width / 320) * 320),
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'white',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            marginTop: height - 100,
            marginBottom: 40,
            FeedContainer: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                GridFeed: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                },
            },
        },
    },
    TopButtonContainer: {
        position: 'fixed',
        top: 0, left: 0, width: '100%', display: 'inline-block',
        BackButton: {
            float: 'left',
            color: 'white',
            paddingTop: 10,
            paddingLeft: 10,
        },
        MoreButton: {
            float: 'right',
        },
        
    },
    BottomButtons: {
        position: 'fixed',
        bottom: 0, left: 0, width: '100%', display: 'inline-block',
        boxShadow: '0 -1px 12px rgba(0, 0, 0, 0.05), 0 -1px 12px rgba(0, 0, 0, 0.05)',
        backgroundColor: 'white',
        paddingTop: 10,
        Child: {
            float: 'left',
            width: '33.333333%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //border: '1px lightgray solid',
            borderRadius: 0,
            Title: {
                paddingBottom: 7,
                paddingLeft: 5,
            }
        }
    },
}};

/**
 * 
 * @param {boolean} isOpen 
 * @param {boolean} isActive 
 */
export const ExpandableGrid = (parentWidth, isOpen, isActive, size = GRID_SIZE, heightRatio = 1, marginRatio = GRID_MARGIN_RATIO) => ({
    position: 'relative',
    padding: 0,
    marginLeft: 0, marginRight:0, marginBottom:0, marginTop: 0,
    width: size * (1 + marginRatio * 2),
    height: size * (heightRatio + marginRatio * 2),
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    MsUserSelect: 'none',
    Grid: {
        width: size * (1 + marginRatio * 2),
        height: size * (heightRatio + marginRatio * 2),
        top: 0, left: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        Container: {
            ThumbnailContainer: MergeAll({
                overflow: 'hidden',
                display: 'flex',
                width: size,
                height: size * heightRatio,
                Thumbnail: {
                    width:'100%',
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                },
            }, NOROUND_NOSHADOW_BOX),
            LabelContainer: {
                position: 'absolute',
                bottom: 0, right: 0,
                ServiceAvatar: {display: 'none'},
                Avatar: {display:'none'},
                Labels: {
                    Title: {
                        position: 'absolute',
                        bottom: (size * marginRatio) + 5 || 5, right: 10,
                        color: 'white',
                        fontSize: '0.7rem',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        maxWidth: size - 20,
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 1)',
                    },
                    Subtitle: {
                        display: 'none'
                    }
                },
                More: {display: 'none'}
            }
        },
        ActiveGrid:
        MergeAll(
            isActive?{
                opacity: 1,
                left: 0,
                top: 0,
                width: size * (1 + marginRatio * 2),
                height: size * (heightRatio + marginRatio * 2),
            }:{
                opacity: 0,
                left: size * marginRatio,
                top: size * marginRatio,
                width: size,
                height: size * heightRatio,
            },{
                transition: '200ms',
                position: 'absolute',
                zIndex: 1,
                ThumbnailContainer: MergeAll(
                    NOROUND_NOSHADOW_FLOATED_BOX,{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    Thumbnail: {
                        width:'100%',
                        height: '100%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    },
                }),
                Title: {},
            }
        ),
        OpenGrid:
        MergeAll(
            isOpen?{
                display: 'flex',
            }:{display:'none'}, {
                position: 'fixed',
                top: 0, left: 0,
                width: '100%', height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)',
                zIndex: 2,
                Background: {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                },
                Card: {
                    zIndex: 3,
                    backgroundColor: 'white',
                    borderRadius: 20,
                    overflow: 'hidden',
                    maxWidth: Math.min(parentWidth-20, 400),
                    ThumbnailContainer: {
                        Thumbnail: {width: '100%', maxHeight: 300, objectFit: 'cover'},
                    },
                    ThumbTitleContainer: {
                        display: 'flex',
                        padding: 2,
                        Avatar: {padding:0, margin:2, minWidth: 40},
                        Title: {padding: 8},
                    },
                    Description: {
                        padding: 8,
                        height: '2rem',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        wordWrap: 'keep-all',
                        overflow: 'hidden',
                        fontStyle: 'italic',
                    },
                    Actions: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        Button: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    },
                }
            }
        ),
    },
    BackGround: {},
});

/**
 * 
 * @param {number} parentWidth 
 * @param {boolean} isOpen 
 * @param {boolean} isActive 
 */
export const YoutubeCard = (parentWidth, isOpen, isActive) => ({
    position: 'relative',
    padding: 0,
    marginTop: 0, marginLeft:0, marginRight: 0,
    width: '100%',
    height: CARD_HEIGHT - 40,
    marginBottom: 40,
    Grid: {
        width: '100%',
        height: CARD_HEIGHT * 2/3,
        top: 0, left: 0,
        position: 'absolute',
        Container: {
            display: 'block',
            width: '100%',
            ThumbnailContainer: {
                overflow: 'hidden',
                display: 'flex',
                width: '100%',
                height: CARD_HEIGHT * 2/3,
                Thumbnail: {
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            LabelContainer:{
                display:'flex',
                alignItems: 'center',
                margin: 10,
                height: 40,
                ServiceAvatar: {width: 40, height: 40},
                Avatar: {width: 40, height: 40},
                Labels: {
                    marginLeft: 10,
                    maxWidth: parentWidth - 160,
                    Title: {overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                    Subtitle: {fontSize: '0.7rem', color: 'grey', overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                },
                More: {position: 'absolute', right: 0},
            }
        },
        OpenGrid:
        MergeAll(
            isOpen?{display: 'flex',}:{display:'none'},
            {
                position: 'fixed',
                top: 0, left: 0,
                width: '100%', height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)',
                zIndex: 2,
                Background: {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                },
                Card: {
                    zIndex: 3,
                    backgroundColor: 'white',
                    borderRadius: 20,
                    overflow: 'hidden',
                    maxWidth: Math.min(parentWidth-20,400),
                    ThumbnailContainer: {
                        Thumbnail: {width: '100%', maxHeight: 300, objectFit: 'cover'},
                    },
                    ThumbTitleContainer: {
                        display: 'flex',
                        padding: 2,
                        Avatar: {padding:0, margin:2, minWidth: 40},
                        Title: {padding: 8},
                    },
                    Description: {
                        padding: 8,
                        height: '2rem',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        wordWrap: 'keep-all',
                        overflow: 'hidden',
                        fontStyle: 'italic',
                    },
                    Actions: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        Button: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    },
                }
            }
        ),
        
    },
    BackGround: {},
});

/**
 * 
 * @param {number} parentWidth 
 * @param {boolean} isOpen 
 * @param {boolean} isActive 
 */
export const NametagCard = (parentWidth, ) => ({
    position: 'relative',
    padding: 0,
    marginTop: 0, marginLeft:10, marginRight: 10,
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    height: 60,
    marginBottom: 2,
    flexShrink: 0,
    Grid: {
        height: 60,
        Container: {
            display: 'flex',
            flexShrink: '0',
            height: '100%',
            ThumbnailContainer: {
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                height: 60,
                Thumbnail: {
                    width: 60,
                    height: 60,
                    objectFit: 'cover',
                },
            },
            LabelContainer:{
                position: 'relative',
                display:'flex',
                alignItems: 'center',
                margin: 5,
                width: parentWidth>800?parentWidth-400-20:parentWidth-120,
                height: 50,
                ServiceAvatar: {position: 'absolute', left: -5, bottom: -10, width: 20, height: 20, zIndex:1},
                Avatar: {width: 50, height: 50},
                Labels: {
                    marginLeft: 10,
                    maxWidth: parentWidth>800?parentWidth-400-80:parentWidth - 170,
                    Title: {overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                    Subtitle: {fontSize: '0.7rem', color: 'grey', overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                },
                More: MergeAll({position: 'relative', marginLeft:10, width: 40, height: 60,}, parentWidth>800?{display: 'none'}:{}),
            },
            Detail: {
                display: 'flex',
                flexDirection: 'row',
                flexShrink: 0,
                marginLeft: 2,
                marginRight: 2,
                height: 60,
                width: 280,
                MetaRow: {
                    display: 'flex',
                    flexDirection: 'column',
                    HeaderCell: {
                        width: 50,
                        height: 20,
                        fontSize: '0.6rem',
                        paddingLeft: 5, paddingRight: 5,
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        lineHeight: '20px',
                    },
                    RecommHeaderCell: {
                        width: 50,
                        height: 20,
                        fontSize: '0.6rem',
                        paddingLeft: 5, paddingRight: 5,
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        lineHeight: '20px',
                    },
                    BodyCell: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 50,
                        height: 40,
                        fontSize: '1rem',
                        paddingLeft: 5,
                        paddingRight: 5,
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        Inner: {maxWidth: '100%', maxHeight: '100%'},
                    }
                }
            },
            Actions: {
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                Button: {
                    width: 40,
                    height: 50,
                    paddingTop: 8,
                    paddingBottom: 0, paddingLeft: 0, paddingRight: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    Label: {
                        marginTop: 9,
                        textAlign: 'center',
                        fontSize: '0.6rem',
                    }
                },
            },
        },
    },
    BackGround: {},
});

export const FlexYoutubeCard = (parentWidth, isOpen, isActive) =>  MergeAll(
    YoutubeCard(CARD_HEIGHT, isOpen, isActive),
    {
        width: CARD_HEIGHT,
        display: 'flex',
        marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10,
        overflow: 'hidden',
    },
    isActive?ROUNDED_SHADOWED_FLOATED_BOX:ROUNDED_SHADOWED_BOX,
)

/**
 * 
 * @param {number} parentWidth 
 * @param {boolean} isOpen 
 * @param {boolean} isActive 
 */
export const FlatCard = (parentWidth, isOpen, isActive) => ({
    position: 'relative',
    padding: 0,
    marginTop: 0, marginLeft:0, marginRight: 0,
    width: '100%',
    height: CARD_HEIGHT - 40,
    marginBottom: 40,
    Grid: {
        width: '100%',
        height: CARD_HEIGHT * 2/3,
        top: 0, left: 0,
        position: 'absolute',
        Container: {
            display: 'block',
            width: '100%',
            ThumbnailContainer: {
                overflow: 'hidden',
                display: 'flex',
                width: '100%',
                height: CARD_HEIGHT * 2/3,
                Thumbnail: {
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            LabelContainer:{
                position: 'relative',
                display:'flex',
                alignItems: 'center',
                margin: 10,
                height: 40,
                ServiceAvatar: {width: 20, height: 20, position: 'absolute', top: -20, left: (parentWidth-60)/2-10, zIndex:1},
                Avatar: {width: 40, height: 40, position: 'absolute', top: -40, left: (parentWidth-60)/2},
                Labels: {
                    marginLeft: 0,
                    maxWidth: parentWidth - 20,
                    Title: {fontSize: '0.8rem', overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                    Subtitle: {fontSize: '0.7rem', color: 'grey', overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                },
                More: {position: 'absolute', right: 0, display:'none'},
            }
        },
        OpenGrid:
        MergeAll(
            isOpen?{display: 'flex',}:{display:'none'},
            {
                position: 'fixed',
                top: 0, left: 0,
                width: '100%', height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.3)',
                zIndex: 2,
                Background: {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                },
                Card: {
                    zIndex: 3,
                    backgroundColor: 'white',
                    borderRadius: 20,
                    overflow: 'hidden',
                    maxWidth: Math.min(parentWidth-20,400),
                    ThumbnailContainer: {
                        Thumbnail: {width: '100%', maxHeight: 300, objectFit: 'cover'},
                    },
                    ThumbTitleContainer: {
                        display: 'flex',
                        padding: 2,
                        Avatar: {padding:0, margin:2, minWidth: 40},
                        Title: {padding: 8},
                    },
                    Description: {
                        padding: 8,
                        height: '2rem',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        wordWrap: 'keep-all',
                        overflow: 'hidden',
                        fontStyle: 'italic',
                    },
                    Actions: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        Button: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    },
                }
            }
        ),
        
    },
    BackGround: {},
});


export const FlatFlexCard = (parentWidth, isOpen, isActive) =>  MergeAll(
    FlatCard(150, isOpen, isActive),
    {
        width: 150,
        display: 'flex',
        margin: 5,
        overflow: 'hidden',
        backgroundColor: 'rgba(0,0,0,0.05)',
        borderRadius: 10,
        userSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        MsUserSelect: 'none',
    },
    isActive?NOROUND_NOSHADOW_FLOATED_BOX:{},
)

export const FlatVSCard = (parentWidth, parentHeight, isOpen, isActive) => {
    const isNarrow = parentWidth < 420;

    const CARD_WIDTH = isNarrow?Math.min(parentWidth - 10, 400):Math.min(parentWidth * 0.45, 400);
    const CARD_HEIGHT = Math.min(parentHeight * 0.6, 600);
    return MergeAll(
    {
        position: 'relative',
        padding: 0,
        marginTop: 0, marginLeft:isNarrow?0:20, marginRight: isNarrow?0:20,
        marginBottom: isNarrow?0:40,
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        display: 'flex',
        overflow: 'hidden',
        transition: '200ms',
        Grid: {
            width: '100%',
            height: CARD_HEIGHT,
            top: 0, left: 0,
            position: 'absolute',
            Container: {
                display: 'block',
                width: '100%',
                ThumbnailContainer: {
                    overflow: 'hidden',
                    display: 'flex',
                    width: '100%',
                    height: isNarrow?CARD_HEIGHT-120:CARD_HEIGHT - 100 - 60,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    alignItems: 'center',
                    justifyContent: 'center',
                    Thumbnail: {
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    },
                },
                Description: {
                    marginLeft: 20, marginRight: 20,
                    marginTop: isNarrow?10:20, marginBottom: isNarrow?10:20, 
                    //backgroundColor: 'rgba(0,0,0,0.3)',
                    //color: 'white',
                    width: CARD_WIDTH - 70,
                    height: isNarrow?40:62,
                    transition: '200ms',
                    wordBreak: 'keep-all',
                    fontStyle: 'italic',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    WebkitLineClamp: isNarrow?2:3,
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                },
                LabelContainer:{
                    margin: 10,
                    display: 'inline-block',
                    Avatar: {float: 'left', width: 40, height: 40},
                    Labels: {
                        float: 'left',
                        marginLeft: 10,
                        maxWidth: parentWidth - 160,
                        Title: {overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                        Subtitle: {fontSize: '0.7rem', color: 'grey', overflowX:'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'},
                    },
                    More: {float: 'right'},
                }
            },            
        },
        BackGround: {},
    },
    isActive?NOROUND_NOSHADOW_FLOATED_BOX:NOROUND_NOSHADOW_BOX,
)}

export const Main = (parentWidth, parentHeight) => ({
    overflowX: 'hidden',
    paddingLeft:24,
    Carousel: {
        display: 'block',
        width: '100%',
        height: CARD_HEIGHT * 1,
        margin: CARD_HEIGHT * 0.1,
        overflowX: 'auto',
        overflowY: 'hidden',
        Container: {
            display: 'flex',
            flexGrow: 1,
            Child: {
                flexShrink: 0,
            }
        }
    },
    TagContainer: {
        position: 'fixed',
        top: 0, left: 0, width: '100%',
        backgroundColor: 'white',
        zIndex: 2,
        TagSlider: {
            whiteSpace: 'nowrap',
            paddingLeft: 30,
            paddingRight: 30,
            Tag: {
                display: 'inline-block',
                fontSize: '1.4rem',
                color: 'rgba(0,0,0,0.45)',
                padding: '0.2rem',
            }
        }
    },
    FeedContainer: {
        display: 'flex',
        GridFeed: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            flexShrink: 0,
        },
    },
});

export const Tutorial = (width, height, progress, pageCount) => {

    const isNarrow = width < 420;

    const LogoAnimatedSize = {
        width: Math.min(400, width * 0.6, height / 2.06 * 0.8),
        height: 100
    };
    const phoneSize = {
        width: LogoAnimatedSize.width / 1.2,
        height: LogoAnimatedSize.width / 1.2 * 2.06
    };
    const phonePosition = {
        x: isNarrow
            ?(width - phoneSize.width)/2
            :width/2<phoneSize.width * 2
                ?width - phoneSize.width * 1.5
                :width * 3 / 4 - phoneSize.width / 2,
        y: isNarrow
            ?(height - phoneSize.height)*3/4
            :(height - phoneSize.height)/2
    };
    const LogoAnimatedPosition = {
        x: phoneSize.width * 0.9/2  - LogoAnimatedSize.width/2,
        y: phoneSize.height * 0.9/2 - LogoAnimatedSize.height/2,
    }
    
    return {
        height: height,
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        Pages:{
            overflowX: 'hidden',
            Page: {
                width: isNarrow?width - 80:width * 0.5 - 40,
                height: height - 80,
                color: "#FFFFFF",
                paddingTop: 40,
                paddingLeft: 40,
                paddingBottom: 40,
                paddingRight: isNarrow?40:'50%',
                fontSize: "2rem",
                wordBreak: 'keep-all',
            }
        },
        Phone: { // 400x820 = LogoAnimatedSize / 2 x LogoAnimatedSize / 2 * 2.06
            position: 'fixed',
            left: phonePosition.x,
            top: phonePosition.y,
            height: phoneSize.height, 
        },
        PhoneBG: {
            position: 'fixed',
            left: phonePosition.x + phoneSize.width * 0.05,
            top: phonePosition.y + phoneSize.height * 0.05,
            height: phoneSize.height *0.9,
            width: phoneSize.width * 0.9,
            backgroundColor: 'white',
            borderRadius: 20,
        },
        PhoneMask: {
            position: 'fixed',
            left: phonePosition.x + phoneSize.width * 0.05,
            top: phonePosition.y + phoneSize.height * 0.05,
            height: phoneSize.height *0.9,
            width: phoneSize.width * 0.9,
            borderRadius: 20,
            overflow: 'hidden',
        },
        LogoAnimated: MergeAll( LogoAnimatedSize, {
            position: 'relative',
            top: LogoAnimatedPosition.y,
            left: LogoAnimatedPosition.x,
            Child: Array.from(Array(pageCount),()=>0).map((v,index) => {

                const partialProgress = ( progress - index / (pageCount-1) )
                                        /*********************************//
                                                ( 1/ (pageCount-1) )         +0.5;

                return MergeAll(
                    partialProgress >= 0 && partialProgress <= 1
                    ?{
                        position: 'absolute',
                        left: partialProgress * LogoAnimatedSize.width
                            - LogoAnimatedSize.height/2 * ((0.5 - Math.abs(partialProgress - 0.5)) + 0.5),
                        bottom: 0,
                    }:{
                        display: 'none'
                    },
                    {
                        width: LogoAnimatedSize.height/2 * ((0.5 - Math.abs(partialProgress - 0.5)) + 0.5) * 2,
                        height: LogoAnimatedSize.height/2 * ((0.5 - Math.abs(partialProgress - 0.5)) + 0.5) * 2,
                    })
            })
        }),
        BottomButtonContainer: {
            position: 'fixed',
            bottom: 0,
            paddingRight: 30,
            width: width-30,
            ChildButton: {
                float: 'right',
                color: 'white'
            }
        }
    }
};


export const Discover = (parentWidth, parentHeight, scrollY) => {
    return {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    };
};

export const Search = (parentWidth, topicFold, isActive) => {
    return {
        
        Search: {
            display:'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            padding: 20,
            Query: {
                position: 'relative',
                transition: '200ms',
                width: isActive?200:100,
                marginRight: 20,
            },
            SearchIcon: {
                position: 'absolute',
                right: '0',
                bottom: '0.1rem',
                transition: '200ms',
                color: isActive?SIGNATURE_COLOR:"lightgray"
            },
        },
        WeekdayControl: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            maxWidth: parentWidth,
            flexWrap: 'wrap',
            marginBottom: 10,
            Button: {
                width: 40,
                height: 40,
                padding:0,
                whiteSpace: 'nowrap',
                fontSize: '1rem'
            }
        },
        Topic: MergeAll(parentWidth>850?{width: 850, marginLeft: (parentWidth-850)/2}:{},{
            Chip: {padding: 0, margin: 5},
            OpenButton: {transform:topicFold?"":"rotate(180deg)", transition: '100ms'},
        }),
        Directory: MergeAll(parentWidth>850?{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }:{},{
            Header: {
                width: '100%',
                alignItems: 'start',
                justifyContent: 'start',
            },
            ServiceSection: {
                padding: 20,
                flexWrap: 'wrap',
                maxWidth: 850,
                alignItems: 'start',
                justifyContent: 'start',
                NameCard: {
                    display: 'flex',
                    padding: '0.2rem',
                    userSelect: 'none',
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    MsUserSelect: 'none',
                    Avatar: {
                        width: '2.7rem', height: '2.7rem',
                    },
                    Title: {
                        marginTop: '0.1rem',
                        fontSzie: '2.7rem',
                    },
                    Tags: {
                        
                    }
                },
            }
        })
    };
}

export const Setting = (parentWidth, parentHeight,) => {

    return {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        GradientBackground: {
            left: 0, top: 0,
            width: '100%',
            height: parentHeight,
            position: 'absolute',
            backgroundImage: `linear-gradient(180deg, ${SIGNATURE_COLOR} 0px, ${SIGNATURE_COLOR} ${24 + 300 + (Math.min(420, parentWidth) - 48) / 3}px, transparent)`
        },
        SettingContainer: {
            zIndex: 1,
            width: Math.min(420, parentWidth) - 48,
            padding:24,
            Section:{
                paddingBottom: 100,
                SectionHeader: {fontSize:"1.8rem", fontWeight: 550, paddingTop: 10, paddingBottom: 20},
                SectionBody: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    Profile: {
                        Image: {
                            width: (Math.min(420, parentWidth) - 48) / 3,
                            borderRadius: parentWidth/3,
                        },
                        Name: {
                            fontWeight: 'bold',
                        },
                        InterestButtons: {
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                            Button: {
                                fontSize: '0.8rem',
                                paddingLeft: 10,
                                paddingRight: 10,
                            },
                            Divider: {
                                width: 1, height: '0.8rem',
                                borderLeft: '0.5px lightgray solid'
                            }
                        },
                        Grid: {
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            paddingTop: 20,
                            alignItems: 'center',
                            justifyContent: 'center',
                            GridUnit: {
                                width: (Math.min(420, parentWidth) - 48) / 5 - 8,
                            }
                        },
                        ShowMoreButton: {backgroundColor:"rgba(10, 150, 255,1)", color: 'white', fontSize: '0.8rem', marginTop:10, marginBottom: 10},
                        HideButton: {color: 'black', fontSize: '0.8rem', marginTop:10, marginBottom: 10},
                        LogoutButton: {fontSize: '0.8rem'},
                    },
                    CommonSetting: {
                        Row: {
                            fontSize: '0.8rem',
                            borderBottom: '0.5px lightgray solid',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        },
                    },
                    FAQ: {
                        Header: {fontSize: '1.1rem', fontWeight: 500},
                        Body: {paddingLeft: 20, paddingTop: 20, fontSize: '0.8rem', textAlign: 'left'}
                    }
                }
            }
            
        }
    }
}