import React, { useEffect } from 'react';

import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Avatar} from '@material-ui/core';

import Main from './Page/Home';
import Match from './Page/Match';
import Search from './Page/SearchAsList';
import Setting from './Page/Setting';

import Service from './Page/Service';
import Detail from './Page/Detail';
import PrivacyPolicy from './Page/PrivacyPolicy';
import PrivacyPolicyKr from './Page/PrivacyPolicyKr';
import Tutorial from './Page/Tutorial';

import AppBar from './Component/AppBar';

import { logBehavior } from './Data/Behavior';
import Toast from './Component/Toast';

import {serviceAlias, BranchFunction, thumbnailize, fn} from './Functions';

import Meta from './Page/Admin/Meta';
import { hasPushPermission } from './Data/Push';

/** @typedef {{width: number, height: number}} size */
/** @typedef {{x: number, y: number}} point */

 /**
 *  @typedef {import('./App').globalDataType & { 
 *   goto: (urlOrPath: string, by?: string) => void,
 *   windowSize: size,
 *   windowScrollY: number,
 *   path: string,
 *   queries: {},
 * }} rountingDataType  */

let latelyKnownPath = "";

 /**
  * 
  * @param {string} path 
  * @param {rountingDataType} newProps 
  * @returns {() => JSX.Element}
  */
const jsxBranch = (path, newProps) => BranchFunction(
    path,
    [
        {
            validator: (path) => path.startsWith("/detail"),
            sublogic: [
                {
                    validator: (path) => /\/detail\/.+\/([^/#]+)/.exec(path),
                    defaultValue: () => <Detail {...newProps}/>,
                }
            ],
            defaultValue: () => {
                let [, serviceId, ] = /\/detail\/([^/]+)/.exec(path) || ['', '']
                return <Service serviceId={serviceId} {...newProps}/>
            },
        },
        {
            validator: (path) => path.startsWith("/privacypolicy"),
            sublogic:[
                {
                    validator: (path) => path.startsWith("/privacypolicy/kr"),
                    defaultValue: () => <PrivacyPolicyKr {...newProps}/>,
                },
            ],
            defaultValue: () => <PrivacyPolicy/>,
        },
        {
            validator: (path) => path.startsWith("/tutorial"),
            defaultValue: () => <Tutorial {...newProps}/>,
        },
        {
            validator: (path) => path.startsWith("/match"),
            defaultValue: () => <Match {...newProps}/>,
        },
        {
            validator: (path) => path.startsWith("/search"),
            defaultValue: () => <Search {...newProps}/>,
        },
        {
            validator: (path) => path.startsWith("/setting"),
            defaultValue: () => <Setting {...newProps}/>,
        },
        {
            validator: (path) => path.startsWith("/admin") && newProps.session.email && (newProps.session.email.endsWith('@webtoon.today') || newProps.session.email.endsWith('@webtoontoday.com')),
            defaultValue: () => <Meta {...newProps}/>,
        },
    ],
    () => <Main {...newProps}/>,
)

/**
 * 
 * @param {string} path 
 * @param {rountingDataType} newProps 
 * @returns {() => string}
 */
export const titleBranch = (path, newProps, queries) => BranchFunction(
   path.replace(window.location.origin),
   [
       {
            validator: (path) => path.startsWith("/detail"),
            sublogic: [
                {
                    validator: (path) => /\/detail\/(.+)\/([^/#]+)/.exec(path),
                    defaultValue: () => {
                        if (!/\/detail\/(.+)\/([^/#]+)/.exec(path)){
                            return ""
                        }
                        const [,serviceId,titleId] = /\/detail\/(.+)\/([^/#]+)/.exec(path)

                        const title = (newProps.titles.filter(row => row.serviceId === serviceId && row.titleId === titleId) || [null])[0]

                        if (!title){
                            return "오늘의 웹툰";
                        }else{
                            return `[${(serviceAlias[serviceId] || []).slice(-1)[0]}]${title.title} - 오늘의 웹툰`
                        }
                    },
                }
            ],
            defaultValue: () => {
                const [,serviceId] = /\/detail\/([^/#]+)/.exec(path) || ['', '']
                return `${(serviceAlias[serviceId] || []).slice(-1)[0] || serviceId} 모아보기 - 오늘의 웹툰`
            },
       },
       {
           validator: (path) => path.startsWith("/privacypolicy"),
           defaultValue: () => '개인정보 처리 방침 - 오늘의 웹툰',
       },
       {
           validator: (path) => path.startsWith("/tutorial"),
           defaultValue: () => '시작하기 - 오늘의 웹툰',
       },
       {
           validator: (path) => path.startsWith("/match"),
           defaultValue: () => '취향찾기 - 오늘의 웹툰',
       },
       {
           validator: (path) => path.startsWith("/setting"),
           defaultValue: () => newProps.session && newProps.session.name?'내 정보 - 오늘의 웹툰':'환경 설정 - 오늘의 웹툰',
       },
       {
           validator: (path) => path.startsWith("/search"),
           sublogic: [
               {
                   validator: () => queries && queries.query,
                   defaultValue: () => `${queries.query} 웹툰 검색결과 - 오늘의 웹툰`,
               }
           ],
           defaultValue: () => '웹툰찾기 - 오늘의 웹툰',
       },
   ],
   () => '오늘의 웹툰',
)

/**
 * 
 * @param {string} path 
 * @param {rountingDataType} newProps 
 * @returns {() => string}
 */
export const descriptionBranch = (path, newProps, queries) => BranchFunction(
    path.replace(window.location.origin),
    [
        {
             validator: (path) => path.startsWith("/detail"),
             sublogic: [
                 {
                     validator: (path) => /\/detail\/(.+)\/([^/#]+)/.exec(path),
                     defaultValue: () => {
                         if (!/\/detail\/(.+)\/([^/#]+)/.exec(path)){
                             return ""
                         }
                         const [,serviceId,titleId] = /\/detail\/(.+)\/([^/#]+)/.exec(path)
 
                         const title = (newProps.titles.filter(row => row.serviceId === serviceId && row.titleId === titleId) || [null])[0]
 
                         if (!title){
                             return "오늘의 웹툰";
                         }else{
                             return `[${(serviceAlias[serviceId] || []).slice(-1)[0]}]${title.title} 재밌게 보셨다면 이작품도 추천합니다`
                         }
                     },
                 }
             ],
             defaultValue: () => {
                 const [,serviceId] = /\/detail\/([^/#]+)/.exec(path)
                 return `${(serviceAlias[serviceId] || []).slice(-1)[0]} 모아보기`
             },
        },
        {
            validator: (path) => path.startsWith("/match"),
            defaultValue: () => '30초 웹툰 취향찾기. 네이버, 카카오, 다음, 레진, 코미코, 탑툰, 투믹스',
        },
        {
            validator: (path) => path.startsWith("/search"),
            sublogic: [
                {
                    validator: () => queries && queries.query,
                    defaultValue: () => `${queries.query} 검색 결과, ${queries.query} 웹툰 추천`,
                }
            ],
            defaultValue: () => '키워드로 꿀잼웹툰 간편하게 찾기',
        },
    ],
    () => '오늘의 웹툰, 놓치지 마세요! 무료 웹툰을 모아보고 미리보기로 간편하게 이동할 수 있습니다.',
 )
 
/**
 * 
 * @param {string} path 
 * @param {rountingDataType} newProps 
 * @returns {() => string}
 */
export const tagsBranch = (path, newProps, queries) => BranchFunction(
    path.replace(window.location.origin),
    [
        {
             validator: (path) => path.startsWith("/detail"),
             sublogic: [
                 {
                     validator: (path) => /\/detail\/(.+)\/([^/#]+)/.exec(path),
                     defaultValue: () => {
                         if (!/\/detail\/(.+)\/([^/#]+)/.exec(path)){
                             return ""
                         }
                         const [,serviceId,titleId] = /\/detail\/(.+)\/([^/#]+)/.exec(path)
 
                         const title = (newProps.titles.filter(row => row.serviceId === serviceId && row.titleId === titleId) || [null])[0]
 
                         if (!title){
                             return ["오늘의 웹툰", "무료 웹툰", "웹툰 미리보기", "공짜"]
                         }else{
                             return [(serviceAlias[serviceId] || []).slice(-1)[0], title.title,`${title.title} 비슷한`]
                         }
                     },
                 }
             ],
             defaultValue: () => {
                 const [,serviceId] = /\/detail\/(.+)/.exec(path)
                 return [`${(serviceAlias[serviceId] || []).slice(-1)[0]}`,'웹툰추천','미리보기']
             },
        },
        {
            validator: (path) => path.startsWith("/discover"),
            defaultValue: () => ['웹툰추천','인기웹툰','네이버웹툰','다음웹툰','카카오페이지','레진코믹스','코미코','탑툰','투믹스'],
        },
        {
            validator: (path) => path.startsWith("/search"),
            sublogic: [
                {
                    validator: () => queries && queries.query,
                    defaultValue: () => [queries.query, `${queries.query} 웹툰`, `${queries.query} 웹툰 추천`, `${queries.query} 추천`],
                }
            ],
            defaultValue: () => ['웹툰검색','웹툰추천'],
        },
    ],
    () => ["오늘의 웹툰", "무료 웹툰", "웹툰 미리보기", "공짜"],
 )
 


/** @param {import('./App').globalDataType} props
 *  @returns {JSX.Element}
 */
const VirtualRouter = (props) => {

    /** @type {[string, (newUrl:string) => void]} */
    const [url, setUrl] = React.useState(props.location || window.location.href);

    const [toastOpen, setToastOpen] = React.useState(false);
    const [toastSeen, setToastSeen] = React.useState(JSON.parse(localStorage.getItem('toast:onboarding') || 'false'));

    const [discoverToastOpen, setDiscoverToastOpen] = React.useState(false);
    const [discoverToastSeen, setDiscoverToastSeen] = React.useState(JSON.parse(localStorage.getItem('toast:discover') || 'false'));

    React.useEffect(()=>{
        let intervalId = setInterval(()=>{
            if (url !== window.location.href){
                setUrl(window.location.href);
                latelyKnownPath = window.location.pathname;
            }
        }, 50);
        return ()=>{clearInterval(intervalId)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[url]);
    /** @type {[size, (newSize:size) => void]} */
    const [windowSize, setWindowSize] = React.useState({width: window.innerWidth, height: window.innerHeight});
    /** @type {[number, (number) => void]} */
    const [windowScrollY, setWindowScrollY] = React.useState(window.scrollY);

    React.useEffect(()=>{
        window.onresize = () => {
            setWindowSize({width: window.innerWidth, height: window.innerHeight});
        }
        window.onscroll = () => {
            setWindowScrollY(window.scrollY);
        }

    }, []);
    
    React.useEffect(()=>{
        let handler = (event) => {
            logBehavior("goto", {page: latelyKnownPath, to: event.target.location.pathname, by: 'history'});
        }
        window.addEventListener('popstate', handler)

        return () => {
            window.removeEventListener('popstate', handler)
        }
    }, [props.session]);
    /**
     * 
     * @param {string} urlOrPath
     * @param {stirng?} by
     */
    const goto = React.useMemo(() => (urlOrPath, by='button') => {
        logBehavior("goto", {
            to: urlOrPath || "",
            queryString: (url.startsWith("/")?url:url.slice(window.location.origin.length)).split('?').concat([''])[1],
            by
        });
        window.canonicalPath = null;

        if (urlOrPath === undefined || urlOrPath === null) {
            window.history.back();
        }else if (urlOrPath.startsWith("/") || urlOrPath.startsWith(window.location.origin)){
            let queryString = urlOrPath.split('?').concat([''])[1];
            let queryDict = queryString.split("&").map(pair => (([key, value]) => {let ret = {}; ret[key] = decodeURIComponent(value); return ret;})(pair.split("="))).reduce( (a,b)=> Object.assign(a,b));
            
            latelyKnownPath = urlOrPath || "";
            const path = urlOrPath.replace(window.location.origin);
            const title = titleBranch(path, props, queryDict)();
            const description = descriptionBranch(path, props, queryDict)();
            document.title = title;
            document.querySelector("meta[name='description']").setAttribute('content', description);
            if (path.startsWith("/") || path === ""){
                if (!window.bulkProps || !window.bulkProps.main){
                    window.scrollTo(0,0);
                }else{
                    window.scrollTo(0,window.bulkProps.main.lastKnownScrollPos);
                }
                setWindowScrollY(0);
                setUrl(window.location.origin + path);
                window.history.pushState({}, title, window.location.origin + path);
            }
            if (props.isClient && window.gtag){
                if (['127.0.0.1', 'localhost:3000', 'mydomain.com:3000', 'localhost:3001', 'mydomain.com:3001', 'd26pjf3a18hvh4.cloudfront.net'].indexOf(window.location.host) >= 0){
                }else{
                    window.gtag('config', 'G-VSL0CTMT6L', {
                        page_title: title,
                        page_location: window.location.origin + path,
                        page_path: path
                    });
                }
            }
        }else {
            window.open(urlOrPath);
        }
    },[props, url]);

    React.useEffect(()=>{
        fn.goto = goto;
    },[goto]);

    const path = url.startsWith("/")?url:url.slice(window.location.origin.length);
    const queries = url.split('?').concat([''])[1].split('&').map(q => q.split('=')).map( ([key,value])=> {
        let ret = {}
        ret[decodeURIComponent(key)] = decodeURIComponent(value)
        return ret
    }).reduce((a,b) => Object.assign(a,b), {})

    React.useEffect(()=>{
        if (!toastSeen && !path.startsWith('/onboarding') && !path.startsWith('/match') && !path.startsWith('/tutorial')){
            let timeoutId = setTimeout(()=>{
                setToastOpen(true)
            }, 1000);
            return ()=>{clearTimeout(timeoutId)}
        }else {
            setToastOpen(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path])

    React.useEffect(()=>{
        if (!toastOpen && toastSeen && !discoverToastSeen && path.startsWith('/discover')){
            let timeoutId = setTimeout(()=>{
                setDiscoverToastOpen(true)
            }, 1000);
            return ()=>{clearTimeout(timeoutId)}
        }else{
            setDiscoverToastOpen(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path, toastOpen, toastSeen])

    const {query, setQuery, titles} = props;
    useEffect(()=> {
        if (query && query.length > 0 && path.startsWith("/search")){
            for (const serviceId of Object.keys(serviceAlias) ){    
                if (serviceAlias[serviceId].indexOf(query.replace(/웹툰|코믹스| /g,''))>=0){
                    goto(`/detail/${serviceId}`)
                    return;
                }
            }
            if (titles.length === 1){
                goto(`/detail/${titles[0].serviceId}/${titles[0].titleId}`)
                return;
            }
        }
        let queryString = path.split('?').concat([''])[1];
        let queryDict = queryString.split("&").map(pair => (([key, value]) => {let ret = {}; ret[key] = decodeURIComponent(value); return ret;})(pair.split("="))).reduce( (a,b)=> Object.assign(a,b));
        document.title = titleBranch(path, {titles}, queryDict)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[query, setQuery, titles, path]);

    /** @type {rountingDataType} */
    const newProps = {
        ...props,
        goto, windowSize, windowScrollY, path, queries
    };

    const CORE  = (
    <>
        <div style={{width:'100%', height:'100%', marginBottom: 48}}>
            {jsxBranch(path, newProps)()}
        </div>
        <AppBar {...newProps}/>
    </>
    );

    if (!props.isClient){
        return (
            <div style={{width:'100%', height:'100%', position: 'relative'}}>
                {CORE}
            </div>
        );
    }

    return (
        <div style={{width:'100%', height:'100%', position: 'relative'}}>
            {CORE}
            <Toast
                message={[
                    props.titles.length>=2?(<Avatar key={0} style={{width:'1.5rem', height: '1.5rem'}} src={thumbnailize(titles[0].thumbnail, 40)} alt={"candidate1"}/>):<div key={0}></div>,
                    <div key={1}>{props.titles.length>=2?"vs":""}</div>,
                    props.titles.length>=2?(<Avatar key={2} style={{width:'1.5rem', height: '1.5rem'}} src={thumbnailize(titles[1].thumbnail, 40)} alt={"candidate2"}/>):<div key={2}></div>,
                    <div key={3}>{"　재미있는 웹툰, 찾고 계세요?"}</div>
                ]}
                animationType={'up'}
                isOpen={toastOpen}
                onClick={()=>{
                    goto('/match')
                    logBehavior('toastClick', {type: 'onboarding'})
                    setToastOpen(false)
                    setToastSeen(true)
                    localStorage.setItem('toast:onboarding', 'true')
                }}
                windowSize={windowSize}
                onClose={()=>{
                    setToastOpen(false)
                    setToastSeen(true)
                }}
            />
            <Toast
                message={"작품을 길게 누르면 자세한 정보를 볼 수 있습니다."}
                animationType={'up'}
                isOpen={discoverToastOpen}
                onClick={()=>{
                    setDiscoverToastOpen(false)
                    setDiscoverToastSeen(true)
                    localStorage.setItem('toast:discover', 'true')
                }}
                windowSize={windowSize}
                onClose={()=>{
                    setDiscoverToastOpen(false)
                    setDiscoverToastSeen(true)
                }}
            />
            <Dialog
                open={props.isLoginWarningShown}
            >
                <DialogTitle>로그인이 필요해요!</DialogTitle>
                <DialogContent>
                    <DialogContentText>로그인 하시겠어요? 아니면, 좀 더 살펴보세요!</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        props.setIsLoginWarningShown(false)
                    }} color="default">
                    닫기
                    </Button>
                    <Button onClick={()=>{
                        goto('/setting');
                        props.setIsLoginWarningShown(false);
                    }} color="primary">
                        로그인하기
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={props.isPushWarningShown}
            >
                <DialogTitle>{hasPushPermission(props.session)?"알림 설정이 완료되었습니다!":"알림 설정을 해주세요!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{hasPushPermission(props.session)
                        ?"이제 [구독]하신 작품의 업데이트를 모아서 받을 수 있어요."
                        :"주소표시줄이나 브라우저 설정에서 알림 설정에 동의해주세요. 최신화 업로드를 알려드려요."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        props.setIsPushWarningShown(false)
                    }} color="default">
                    닫기
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default VirtualRouter;