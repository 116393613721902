import React from 'react';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { readProfile, updateProfile } from '../Data/Profile';
import { fileToBase64} from '../Data/Image';
import { getOffsetInScreen, randomString } from '../Functions';

const invalid_addressid_pattern = /[^0-9a-zA-Z.\-_]/;

/**
 * @typedef {import("../Data/Profile").profileType} profileType
 * @typedef {import('../VirtualRouter').point} point
 * 
 * @param {{
 *  session: import('../Data/Session').sessionType,
 *  sessionRefresh: ()=>Promise<void>,
 *  givenProfile: profileType,
 *  windowSize: import('../VirtualRouter').size,
 * }} props
 */
const Profile = ({session, sessionRefresh, givenProfile, windowSize}) => {
    const [uniqueId,] = React.useState(randomString(16));
    const styleFunc = (parentWidth, ) => ({
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        RowContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            Image: {
                width: (Math.min(420, parentWidth) - 48) / 3,
                borderRadius: parentWidth/3,
                Input: {
                    width: 0,
                    height: 0,
                }
            },
            Text: {
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                fontWeight: 'bold',
            },
        },
        EditButton: {
            position: 'absolute',
            width: '1rem',
            height: '1rem',
        }
    });

    const style = styleFunc(windowSize.width);

    const [profile, setProfile] = React.useState(givenProfile);
    React.useEffect(()=>{
        setProfile(givenProfile);
    },[givenProfile])
    React.useEffect(()=>{
        (async()=>{
            if (profile.addressid && session.userid){
                setProfile(await readProfile(profile.addressid));
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.userid]);

    /** @type {[ 'addressid'|'email'|'image'|'name'|'none' , React.Dispatch<React.SetStateAction<'addressid'|'email'|'image'|'name'|'none'>> ]} */
    const [currentTarget, setCurrentTarget] = React.useState('none');
    const [isEditing, setIsEditing] = React.useState(false);
    React.useEffect(()=>{
        if (isEditing && currentTarget === 'image'){
            document.getElementById(uniqueId+'_newImage').click();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isEditing]);
    
    const [newName, setNewName] = React.useState(profile.name);
    const [newAddressid, setNewAddressId] = React.useState(profile.addressid);
    const [newEmail, setNewEmail] = React.useState(session.email);
    const [errorOnAddressid, setErrorOnAddressid] = React.useState(false);

    React.useEffect(()=>{
        setNewName(profile.name);
        setNewAddressId(profile.addressid);
    },[profile])

    React.useEffect(()=>{
        setNewEmail(session.email);
    },[session])

    /** @type {[ point , React.Dispatch<React.SetStateAction<point>> ]} */
    const [lastKnownPosition, setLastKnownPosition] = React.useState({x:0,y:0});

    const moveButton = (event, target) => {
        if (isEditing){
            return;
        }
        let motherDomBox = getOffsetInScreen(document.getElementById(uniqueId));
        let domBox = getOffsetInScreen(event.target);
        setLastKnownPosition({x: domBox.left - motherDomBox.left + domBox.width - 10, y: domBox.top - motherDomBox.top});
        setCurrentTarget(target);
    }
    
    return (<div id={uniqueId} style={style}>
        <div style={style.RowContainer}>
            <img src={profile.image} style={style.RowContainer.Image} alt={profile.name}
                onMouseEnter={(event) => {
                    moveButton(event,'image');
                    
                }}
                onClick={(event)=>{
                    moveButton(event,'image');
                }}
            />
            <input style={style.RowContainer.Image.Input} type={"file"} name={"newImage"} id={uniqueId+"_newImage"} onChange={async ()=> {
                let data = await fileToBase64(document.getElementById(uniqueId+"_newImage").files[0])
                let newProfile = await updateProfile({image: data})
                if (newProfile){
                    setProfile(newProfile);
                    sessionRefresh();
                }
                setIsEditing(false);
            }}/>
        </div>
        <div style={style.RowContainer}>
            {(isEditing && currentTarget === "name")
            ?<TextField autoFocus inputProps={{style:{textAlign:'center'}}} className={"textfield"} value={newName} onLoad={(event)=>{event.target.querySelector('input').focus()}} onChange={(event) => {
                setNewName(event.target.value);
            }} onSuspend
             onBlur={async ()=>{
                let newProfile = await updateProfile({name: newName});
                if (newProfile){
                    setProfile(newProfile);
                    sessionRefresh();
                }
                setIsEditing(false);
            }} onKeyPress={(event)=>{
                if (event.key === "Enter"){
                    event.target.blur();
                }
            }}/>
            :<div style={style.RowContainer.Text}
                onMouseEnter={(event) => {
                    moveButton(event,'name');
                }}
                onClick={(event)=>{
                    moveButton(event,'name');
                }}
            >
                {profile.name}
            </div>}
        </div>
        <div style={style.RowContainer}>
            
            {(isEditing && currentTarget === "addressid")
            ?<TextField autoFocus inputProps={{style:{textAlign:'center'}}} error={errorOnAddressid} className={"textfield"} value={newAddressid} onLoad={(event)=>{event.target.querySelector('input').focus()}} onChange={(event) => {
                if (invalid_addressid_pattern.exec(event.target.value)){
                    setErrorOnAddressid(true);
                    return;
                }
                setErrorOnAddressid(false);
                setNewAddressId(event.target.value);
            }} onBlur={async ()=>{
                let newProfile = await updateProfile({addressid: newAddressid});
                if (newProfile){
                    setProfile(newProfile);
                    sessionRefresh();
                }
                setIsEditing(false);
            }} onKeyPress={(event)=>{
                if (event.key === "Enter"){
                    event.target.blur();
                }
            }} helperText={errorOnAddressid?"사용가능 문자: 0-9 a-z A-Z ._-":""}/>
            :<div style={{ ...(style.RowContainer.Text), color: !profile.addressid?'rgba(0,0,0,0.45)':'inherit' }}
                onMouseEnter={(event) => {
                    moveButton(event,'addressid');
                }}
                onClick={(event)=>{
                    moveButton(event,'addressid');
                }}
            >
                {profile.addressid || "공개 ID 없음"}
            </div>}
        </div>
        <div style={style.RowContainer}>
            {(isEditing && currentTarget === "email")
            ?<TextField autoFocus inputProps={{style:{textAlign:'center'}}} value={newEmail} className={"textfield"} onLoad={(event)=>{event.target.querySelector('input').focus()}} onChange={(event) => {
                setNewEmail(event.target.value);
            }} onBlur={async ()=>{
                let newProfile = await updateProfile({email: newEmail});
                if (newProfile){
                    await sessionRefresh();
                }
                setIsEditing(false);
            }} onKeyPress={(event)=>{
                if (event.key === "Enter"){
                    event.target.blur();
                }
            }}/>
            :<div style={{ ...(style.RowContainer.Text), color: !session.email?'rgba(0,0,0,0.45)':'inherit' }}
                onMouseEnter={(event) => {
                    moveButton(event,'email');
                }}
                onClick={(event)=>{
                    moveButton(event,'email');
                }}
            >
                {session.email || "email 없음"}
            </div>}
        </div>
        {!isEditing && currentTarget !== "none" && (session.userid === givenProfile.userid)
        ?<Tooltip title={
            currentTarget==="image"     ?"프로필 사진입니다. 640x640 이상의 사진이 권장됩니다. 프로필 페이지, 댓글 등에 노출됩니다."
            :currentTarget==="name"     ?"자유로운 형식의 이름입니다. 프로필 페이지, 댓글 등에 노출됩니다."
            :currentTarget==="addressid"?"영어 알파벳과 ._-만 허용되는 이름입니다. 접근 주소로 사용됩니다."
            :/*currentTarget==="email"?*/"이메일주소입니다. 외부에 노출되지 않습니다."
        }>
            <IconButton
                size={"small"}
                style={{ ...(style.EditButton), left: lastKnownPosition.x, top: lastKnownPosition.y }}
                onClick={()=>{setIsEditing(true)}}
            ><Edit style={style.EditButton}/></IconButton>
        </Tooltip>
        :<></>}
    </div>);
}

export default Profile;