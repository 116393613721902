import React from 'react';
import {NametagCard as NametagCardStyle, } from '../Styles';
import {Avatar, Button, IconButton, Tooltip, } from '@material-ui/core';
import { DoubleArrow, Home } from '@material-ui/icons';
import ServiceAvatar from './ServiceAvatar';

import {randomString, MergeAll, thumbnailize, dateFormat, } from '../Functions';
import { logBehavior } from '../Data/Behavior';

let scrollTimeoutTrigger = null;
/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
 */
export const SwipeListItem = (props) => {

    const [uniqueId, ] = React.useState('swipeListItem_'+randomString(16));

    const item = props.item;
    
    const [style, setStyle] = React.useState(NametagCardStyle(props.windowSize.width, false, false));
    React.useEffect(()=>{
        setStyle(NametagCardStyle(props.windowSize.width, false, false));
    },[props.windowSize.width])

    React.useEffect(()=>{
        const elem = document.getElementById(uniqueId).parentElement;

        const scrollCallback = (event) => {
            elem.querySelector('.more').style.transform = `rotate(${(1+elem.scrollLeft/(elem.scrollWidth-elem.clientWidth))*180}deg)`;
            if (scrollTimeoutTrigger){
                clearTimeout(scrollTimeoutTrigger);
            }
            scrollTimeoutTrigger = setTimeout(()=>{
                if (elem.scrollLeft === 0){
                    logBehavior("detailClose", {currentItem: item, by: 'swipe'});
                } else if (elem.scrollLeft === elem.scrollWidth-elem.clientWidth){
                    logBehavior("detailOpen", {currentItem: item, by: 'swipe'});
                } else if (elem.scrollLeft < (elem.scrollWidth-elem.clientWidth)*(1/2)){
                    elem.scrollTo({left: 0, behavior: 'smooth'});
                }else if (elem.scrollLeft >= (elem.scrollWidth-elem.clientWidth)*(1/2)){
                    elem.scrollTo({left: elem.scrollWidth-elem.clientWidth, behavior: 'smooth'});
                }
            }, 100);
        };

        elem.addEventListener('scroll', scrollCallback, {passive:true});
        elem.addEventListener('touchmove', scrollCallback, {passive:true});
        return () => {
            elem.removeEventListener('scroll',scrollCallback);
            elem.removeEventListener('touchmove', scrollCallback);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, uniqueId]);

    // place holder
    if (!item) {
        return (
            <div >
            </div>
        );
    }

    return (
        <div style={MergeAll(style, props.style || {maxWidth: props.windowSize.width})}
        >
            <div id={uniqueId} className={"grid"} style={style.Grid}>
                <div className={"container"} style={style.Grid.Container}>
                    {style.Grid.Container.LabelContainer && style.Grid.Container.LabelContainer.Labels 
                    ?<div style={style.Grid.Container.LabelContainer}>
                        <ServiceAvatar style={style.Grid.Container.LabelContainer.ServiceAvatar} serviceId={item.serviceId} goto={props.goto}/>
                        <Avatar style={style.Grid.Container.LabelContainer.Avatar} imgProps={style.Grid.Container.LabelContainer.Avatar}
                            alt={item.title}
                            src={thumbnailize(item.thumbnail, 40)}
                        />
                        <Button
                            style={{padding:0, margin:0, textAlign:'left', flexGrow:1, justifyContent: 'start'}}
                            onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}
                        >
                            <div style={style.Grid.Container.LabelContainer.Labels} >
                                <div style={style.Grid.Container.LabelContainer.Labels.Title}>{item.title}</div>
                                <div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>{item.tags}</div>
                            </div>
                        </Button>
                    </div>
                    :<div/>}
                    <IconButton
                        className={'more'}
                        style={MergeAll(style.Grid.Container.LabelContainer.More, {transform: `rotate(180deg)`})}
                        onClick={()=>{
                            const elem = document.getElementById(uniqueId).parentElement;
                            if (elem.scrollLeft > (elem.scrollWidth-elem.clientWidth)/2){
                                elem.scrollTo({left: 0, behavior: 'smooth'});
                                logBehavior("detailOpen", {currentItem: item, by: 'button'});
                            }else{
                                elem.scrollTo({left: elem.scrollWidth-elem.clientWidth, behavior: 'smooth'});
                                logBehavior("detailClose", {currentItem: item, by: 'button'});
                            }
                        }}
                    >
                        <DoubleArrow />
                    </IconButton>
                    <div className={"details"} style={style.Grid.Container.Detail}>
                        <div style={style.Grid.Container.Detail.MetaRow}>
                            <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,{fontWeight:'bold'})}>
                                <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{`${
                                    item.is_finished?`완결`
                                    :item.weekday.split('').map((ch,idx) => ch==='1'?['월','화','수','목','금','토','일'][idx]:'').join('')
                                    .replace('월화수목금토일', '매일')
                                    .replace('월수목금토일', '월,수-일')
                                    .replace('월화수목금', '월-금')
                                }`}</div>
                            </div>
                            <div style={style.Grid.Container.Detail.MetaRow.HeaderCell}>{"연재 상태"}</div>
                        </div>    
                        <div style={style.Grid.Container.Detail.MetaRow}>
                            <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,item.episodeCount>30?{fontWeight:'bold'}:{})}>
                                <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{`${item.episodeCount}`}</div>
                            </div>
                            <div style={style.Grid.Container.Detail.MetaRow.HeaderCell}>{"연재 회차"}</div>
                        </div>                        
                        <div style={style.Grid.Container.Detail.MetaRow}>
                            <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,item.freeEpisodeCount>10&&item.freeEpisodeCount>item.episodeCount*0.8?{fontWeight:'bold'}:{})}>
                                <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{`${item.freeEpisodeCount}`}</div>
                            </div>
                            <div style={style.Grid.Container.Detail.MetaRow.HeaderCell}>{"무료 회차"}</div>
                            
                        </div>
                        <div style={style.Grid.Container.Detail.MetaRow}>
                            {((date)=>
                            <>
                                <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,date==='오늘'||date==='어제'||date==='이틀 전'?{fontWeight:'bold'}:{})}>
                                    <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{date}</div>
                                </div>
                                <div style={style.Grid.Container.Detail.MetaRow.HeaderCell}>{"업데이트"}</div>
                            </>)(dateFormat(new Date((item.lastEpisodeUpdatedAt + 9 * 60 * 60)*1000)))
                            }
                        </div>

                        {item.popularity && item.popularity > 1000?
                            <div style={style.Grid.Container.Detail.MetaRow}>
                                <Tooltip title={"누적 조회수 입니다."}>
                                    <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,item.popularity>1000?{fontWeight:'bold'}:{})}>
                                        <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{`${
                                            item.popularity > 10000?
                                                `${Math.floor(item.popularity / 1000)/10} 만`
                                            :item.popularity > 1000?
                                                `${Math.floor(item.popularity / 100)/10} 천`
                                            :   `${item.popularity}`
                                        }`}</div>
                                    </div>
                                </Tooltip>
                                <div style={style.Grid.Container.Detail.MetaRow.RecommHeaderCell}>{"조회"}</div>
                            </div>
                        :item.rank && item.rank < 50?
                            <div style={style.Grid.Container.Detail.MetaRow}>
                                <Tooltip title={"각 서비스에서 자체 선정한 랭킹입니다. 네이버의 경우 요일별로 다른 랭킹을 적용합니다."}>
                                    <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,item.rank<=10?{fontWeight:'bold'}:{})}>
                                        <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{`${item.rank}`}</div>
                                    </div>
                                </Tooltip>
                                <div style={style.Grid.Container.Detail.MetaRow.RecommHeaderCell}>{"랭킹"}</div>
                            </div>
                        :item.rating && item.rating > 8?
                            <div style={style.Grid.Container.Detail.MetaRow}>
                                <Tooltip title={"각 서비스에서 집계중인 별점입니다."}>
                                    <div style={MergeAll(style.Grid.Container.Detail.MetaRow.BodyCell,item.rating>9?{fontWeight:'bold'}:{})}>
                                        <div style={style.Grid.Container.Detail.MetaRow.BodyCell.Inner}>{`${Math.floor(item.rating*10)/10}`}</div>
                                    </div>
                                </Tooltip>
                                <div style={style.Grid.Container.Detail.MetaRow.RecommHeaderCell}>{"별점"}</div>
                            </div>
                        :<></>}
                    </div>
                    <div className={"actions"} style={style.Grid.Container.Actions}>
                        <Button onClick={() => {
                                props.goto(`/detail/${item.serviceId}/${item.titleId}`);
                            }} ><span style={style.Grid.Container.Actions.Button}>
                            <Home/>
                            <div style={style.Grid.Container.Actions.Button.Label}>
                                {"자세히"}
                            </div>
                        </span></Button>
                    </div>
                </div>
            </div>
            <div className={"backgorund"} style={style.Background}/>
        </div>
    );
}

export default SwipeListItem;