import { Tooltip } from '@material-ui/core';
import React from 'react';

import {dateFormat} from '../Functions';

import './ItemDetailInfo.scss';

const ItemDetailInfo = ({item}) => {
    return <div className={"ItemDetailInfo"}>
        <div className={"MetaRow"}>
            <div className={"BodyCell"} style={{fontWeight:'bold'}}>
                <div className={"Inner"}>{`${
                    item.is_finished?`완결`
                    :(item.weekday||'').split('').map((ch,idx) => ch==='1'?['월','화','수','목','금','토','일'][idx]:'').join('')
                    .replace('월화수목금토일', '매일')
                    .replace('월수목금토일', '월,수-일')
                    .replace('월화수목금', '월-금')
                }`}</div>
            </div>
            <div className={"HeaderCell"}>{"연재 상태"}</div>
        </div>    
        <div className={"MetaRow"}>
            <div className={"BodyCell"} style={item.episodeCount>30?{fontWeight:'bold'}:{}}>
                <div className={"Inner"}>{`${item.episodeCount}`}</div>
            </div>
            <div className={"HeaderCell"}>{"연재 회차"}</div>
        </div>                        
        <div className={"MetaRow"}>
            <div className={"BodyCell"} style={item.freeEpisodeCount>10&&item.freeEpisodeCount>item.episodeCount*0.8?{fontWeight:'bold'}:{}}>
                <div className={"Inner"}>{`${item.freeEpisodeCount}`}</div>
            </div>
            <div className={"HeaderCell"}>{"무료 회차"}</div>
            
        </div>
        <div className={"MetaRow"}>
            {((date)=>
            <>
                <div className={"BodyCell"} style={date==='오늘'||date==='어제'||date==='이틀 전'?{fontWeight:'bold'}:{}}>
                    <div className={"Inner"}>{date}</div>
                </div>
                <div className={"HeaderCell"}>{"업데이트"}</div>
            </>)(dateFormat(new Date((item.lastEpisodeUpdatedAt + 9 * 60 * 60)*1000)))
            }
        </div>

        {item.popularity && item.popularity > 1000?
            <div className={"MetaRow"}>
                <Tooltip title={"누적 조회수 입니다."}>
                    <div className={"BodyCell"} style={item.popularity>1000?{fontWeight:'bold'}:{}}>
                        <div className={"Inner"}>{`${
                            item.popularity > 10000?
                                `${Math.floor(item.popularity / 1000)/10} 만`
                            :item.popularity > 1000?
                                `${Math.floor(item.popularity / 100)/10} 천`
                            :   `${item.popularity}`
                        }`}</div>
                    </div>
                </Tooltip>
                <div className={"RecommHeaderCell"}>{"조회"}</div>
            </div>
        :item.rank && item.rank < 50?
            <div className={"MetaRow"}>
                <Tooltip title={"각 서비스에서 자체 선정한 랭킹입니다. 네이버의 경우 요일별로 다른 랭킹을 적용합니다."}>
                    <div className={"BodyCell"} style={item.rank<=10?{fontWeight:'bold'}:{}}>
                        <div className={"Inner"}>{`${item.rank}`}</div>
                    </div>
                </Tooltip>
                <div className={"RecommHeaderCell"}>{"랭킹"}</div>
            </div>
        :item.rating && item.rating > 8?
            <div className={"MetaRow"}>
                <Tooltip title={"각 서비스에서 집계중인 별점입니다."}>
                    <div className={"BodyCell"} style={item.rating>9?{fontWeight:'bold'}:{}}>
                        <div className={"Inner"}>{`${Math.floor(item.rating*10)/10}`}</div>
                    </div>
                </Tooltip>
                <div className={"RecommHeaderCell"}>{"별점"}</div>
            </div>
        :<></>}
    </div>;
}

export default ItemDetailInfo;