import { Button, Chip, Divider, IconButton, TextField } from '@material-ui/core';
import { Done, ErrorOutline, KeyboardArrowDown } from '@material-ui/icons';
import React, { useEffect } from 'react';
import SwipeListItem from '../Component/SwipeListItem';
import ServiceAvatar from '../Component/ServiceAvatar';
import { MergeAll, serviceAlias, setIntersect, setUnion, setSubtraction, ConcatAll } from '../Functions';

import {Search as SearchStyle, SIGNATURE_COLOR} from '../Styles';
import {Search as SearchIcon} from '@material-ui/icons';
import { readTitles } from '../Data/Title';
import { logBehavior } from '../Data/Behavior';

/** @typedef {import('../VirtualRouter').point} point */

/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
    */
export const SearchAsList = (props) => {

    const [isLoading, setIsLoading] = React.useState(true);
  
    if (!window.bulkProps){
        window.bulkProps = {};
    }

    const [topicFold, setTopicFold] = React.useState(true);

    const [tempQuery, setTempQuery] = React.useState(props.query);
    const [searchResult, setSearchResult] = React.useState([]);

    const [weekday, setWeekday] = React.useState((window.bulkProps.Search || {}).weekday || null);

    const [resultMax, setResultMax] = React.useState({});

    React.useEffect(()=>{
        window.canonicalPath = `/search?query=${props.query}`;
        setTempQuery(props.query);
    
        if (props.query &&
            ConcatAll(serviceAlias.naver, serviceAlias.daum, serviceAlias.kakao, serviceAlias.toomics, serviceAlias.toptoon, serviceAlias.lezhin, serviceAlias.comico).indexOf(props.query.replace(/웹툰|코믹스| /g,'')) >= 0){

            // do not refresh! virtualRouter will handle this
            return;
        }
        (async ()=> {
            setIsLoading(true);
            let searched = [];
            if (props.query.trim() === "" && !weekday){
            }else{
                searched = await readTitles(props.query, props.query?100:400, weekday);
            }
            setSearchResult(searched);
            setIsLoading(false);

            window.bulkProps.Search = MergeAll(window.bulkProps.Search || {},{query: props.query, weekday, searched});
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.query, weekday]);

    const [isQueryActive, setQueryIsActive] = React.useState(false);

    const [topics, setTopics] = React.useState(props.queries.topics?new Set(props.queries.topics.split(',')):null || (window.bulkProps.Search || {}).topics || new Set([]));

    useEffect(()=>{
        window.bulkProps.Search = MergeAll(window.bulkProps.Search || {},{ topics: topics.size > 0?topics:new Set([]), });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topics]);

    const [renderable, setRenderable] = React.useState([]);
    React.useEffect(()=>{
        if (props.query.length > 0 || weekday){
            setRenderable(searchResult
                .filter(title => !title.isAdult || props.filterIncludeAdult)
                .filter(title => setIntersect(new Set((title.tags || '').split(',')), topics).size > 0  || (topics.size === 0)))
        }else{
            setRenderable(props.titles
                .filter(title => !title.isAdult || props.filterIncludeAdult)
                .filter(title => setIntersect(new Set((title.tags || '').split(',')), topics).size > 0  || (topics.size === 0)))
        }
    },[props.filterIncludeAdult, props.query.length, props.titles, searchResult, topics, weekday])

    const titlesByService = renderable
        .map( row => {let ret={}; ret[row.serviceId] = [row]; return ret;})
        .reduce( (a,b) => {
            for (const key of Object.keys(b)){
                if (a.hasOwnProperty(key)){
                    a[key] = a[key].concat(b[key])
                }else{
                    a[key] = b[key]
                }
            }
            return a
        }, {})

    const topicCounts = (dict => {
        let tags = Object.keys(dict);
        return tags.map(tag => ([tag, dict[tag]]))
    })(renderable
        .map(title => {
            const tag_list = (title.tags || '').split(",")
            let ret = {}
            for(const tag of tag_list){
                ret[tag] = 1
            }
            return ret;
        })
        .reduce( (a,b) => {
            for(const tag of Object.keys(b)){
                if (a.hasOwnProperty(tag)){
                    a[tag] += b[tag]
                }else{
                    a[tag] = b[tag]
                }
            }

            return a;
        },{}))
    .filter( ([topic, count]) => count > 1 && topic.trim().length > 0 )

    const style = SearchStyle(props.windowSize.width, topicFold, isQueryActive);

    if (isLoading){
        return(<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: props.windowSize.height}}>
            <img src='/loading.gif' alt={"loading"} style={{width: Math.min(200,props.windowSize.width / 3), height: Math.min(200,props.windowSize.width / 3), borderRadus: 20,}}/>
        </div>);
    }

    return (
        <div style={style}>
            <div style={style.Search}>
                
                <div style={style.Search.Query}>
                    <TextField
                        fullWidth={true}
                        value={tempQuery}
                        onChange={event => setTempQuery(event.target.value)}
                        onKeyPress={event => {
                            if( event.key === 'Enter') {
                                event.target.blur();
                            }
                        }}
                        onClick={()=>{setQueryIsActive(true)}}
                        onBlur={()=>{
                            setQueryIsActive(false);
                            if (props.query !== tempQuery){
                                props.setQuery(tempQuery);
                                logBehavior('search', {query: tempQuery});
                            }
                        }}
                    />
                    <SearchIcon style={style.Search.SearchIcon}/>
                </div>
            </div>
            <div style={style.WeekdayControl}>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday===null?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} onClick={()=>setWeekday(null)}>{"전체"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='1000000'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} onClick={()=>setWeekday('1000000')}>{"월"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='0100000'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} onClick={()=>setWeekday('0100000')}>{"화"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='0010000'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} onClick={()=>setWeekday('0010000')}>{"수"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='0001000'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} onClick={()=>setWeekday('0001000')}>{"목"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='0000100'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} onClick={()=>setWeekday('0000100')}>{"금"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='0000010'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} color={"primary"} onClick={()=>setWeekday('0000010')}>{"토"}</IconButton>
                <IconButton style={MergeAll(style.WeekdayControl.Button,weekday==='0000001'?{border:`1px ${SIGNATURE_COLOR} solid`}:{})} color={"secondary"} onClick={()=>setWeekday('0000001')}>{"일"}</IconButton>
            </div>
            <div style={style.Topic}>
                {[...topics.entries()].map( ([topic,]) => [topic, 1])
                .concat(
                    topics.size < 5
                        ?topicCounts
                            .filter(([topic,]) => !topics.has(topic))
                            .slice(0, 5 - topics.size)
                        :[]
                )
                .map( ([topic, count]) =>
                    <Chip
                        key={topic}
                        style={topics.has(topic)
                                ?style.Topic.Chip
                                :MergeAll(style.Topic.Chip,
                                    {
                                        borderColor: 
                                            count<=3?"#C7DAC7":
                                            count<=6?"default":
                                            count<=9?"#FFB68C":
                                            count<=12?"#FE8E7B":
                                                     "#FF6787",
                                        color: 
                                            count<=3?"#C7DAC7":
                                            count<=6?"default":
                                            count<=9?"#FFB68C":
                                            count<=12?"#FE8E7B":
                                                    "#FF6787",
                                    }
                                )}
                        variant={topics.has(topic)?"default":"outlined"}
                        color={"primary"}
                        label={topic}
                        clickable
                        onClick={() => {
                            if (topics.has(topic)){
                                setTopics(setSubtraction(topics, new Set([topic])))
                                logBehavior("topicRemove", {topic, count})
                            }else{
                                setTopics(setUnion(topics, new Set([topic])))
                                logBehavior("topicAdd", {topic, count, on: "top5"})
                            }
                        }}
                        onDelete={()=>{
                            setTopics(setSubtraction(topics, new Set([topic])))
                            logBehavior("topicRemove", {topic, count})
                        }}
                        deleteIcon={topics.has(topic)?<Done/>:<></>}
                    />
                )}
                <IconButton
                    style={style.Topic.OpenButton}
                    onClick={()=>{setTopicFold(!topicFold)}}
                >
                    <KeyboardArrowDown/>
                </IconButton>
                <div>
                    {topicFold
                        ?<></>
                        :(topics.size < 5
                            ?topicCounts
                                .filter(([topic,]) => !topics.has(topic))
                                .slice(5-topics.size)
                            :topicCounts
                                .filter(([topic,]) => !topics.has(topic))
                        )
                            .map( ([topic, count]) =>
                                <Chip
                                    key={topic}
                                    style={MergeAll(style.Topic.Chip,
                                        {
                                            borderColor: 
                                                count<=3?"#C7DAC7":
                                                count<=6?"default":
                                                count<=9?"#FFB68C":
                                                count<=12?"#FE8E7B":
                                                         "#FF6787",
                                            color: 
                                                count<=3?"#C7DAC7":
                                                count<=6?"default":
                                                count<=9?"#FFB68C":
                                                count<=12?"#FE8E7B":
                                                        "#FF6787",
                                        }
                                    )}
                                    variant={"outlined"}
                                    color={"default"}
                                    label={topic}
                                    clickable
                                    onClick={() => {
                                        if (topics.has(topic)){
                                            setTopics(setSubtraction(topics, new Set([topic])))
                                            logBehavior("topicRemove", {topic, count})
                                        }else{
                                            setTopics(setUnion(topics, new Set([topic])))
                                            logBehavior("topicAdd", {topic, count, on: 'bottom'})
                                        }
                                    }}
                                    onDelete={()=>{
                                        setTopics(setSubtraction(topics, new Set([topic])))
                                        logBehavior("topicRemove", {topic, count})
                                    }}
                                    deleteIcon={topics.has(topic)?<Done/>:<></>}
                                />
                            )
                    }
                </div>
            </div>
            <div style={style.Directory}>
                {renderable.length === 0?
                <>
                    <div style={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', padding: 50}}>
                        <ErrorOutline style={{width: 100, height: 100}}/>
                        <div style={{padding:20}}>
                            {"이런! 결과가 없습니다."}
                        </div>
                    </div>
                </>:<></>}
                {Object.keys(titlesByService).map(serviceId => {
                    const titles = titlesByService[serviceId] || []
                    return (<div key={serviceId}>
                        <div style={style.Directory.Header}>
                            <ServiceAvatar serviceId={serviceId} style={{marginLeft:20,}} goto={props.goto}/>
                            <Button onClick={()=>{ props.goto(`/detail/${serviceId}`) }}>{serviceAlias[serviceId][serviceAlias[serviceId].length - 1]}</Button>
                            <Divider/>
                        </div>
                        <div style={style.Directory.ServiceSection}>
                            {titles
                                .slice(0,resultMax[serviceId] || 3)
                                .sort( (titleA, titleB) => 
                                    setIntersect(topics, new Set(titleB.tags?titleB.tags.split(','):[])).size - setIntersect(topics, new Set(titleA.tags?titleA.tags.split(','):[])).size
                                )
                                .map(item => {
                                return (
                                    <SwipeListItem
                                        {...MergeAll(props,{windowSize: {width: Math.min(850,props.windowSize.width), height: props.windowSize.height}})}
                                        style={style.Directory.ServiceSection.NameCard}
                                        key={`${item.serviceId}:${item.titleId}`}
                                        goto={props.goto}
                                        item={item}
                                    />);
                            })}
                            {titles.length>(resultMax[serviceId] || 3)
                            ?<Button fullWidth onClick={()=>{
                                let newResultMax = Object.assign({},resultMax);
                                newResultMax[serviceId] = (newResultMax[serviceId] || 3) + 3;
                                setResultMax(newResultMax);
                            }}>{`더 보기(${resultMax[serviceId] || 3}/${titles.length})`}</Button>:<></>}
                        </div>
                    </div>)
                })}
            </div>
            <div style={{textAlign:'center'}}><Button onClick={()=>props.goto("/privacypolicy")}>{"privacy policy"}</Button></div>
        </div>
    );
}

export default SearchAsList;