import React from 'react';
import {FlatFlexCard as FlexYoutubeCardStyle} from '../Styles';
import {Avatar, Button, IconButton, Tooltip, } from '@material-ui/core';
import {WatchLater, AttachMoney, MoneyOff, FastRewind, Favorite, MoreHoriz as MoreIcon } from '@material-ui/icons';
import ServiceAvatar from './ServiceAvatar';

// @Type
// eslint-disable-next-line no-unused-vars
import {sessionType, googleResponseType, facebookResponseType} from '../Data/Session';
// eslint-disable-next-line no-unused-vars
import {interestFetchType, interestType} from '../Data/Interest';
// eslint-disable-next-line no-unused-vars
import {readTitles, titleType} from '../Data/Title';
// eslint-disable-next-line no-unused-vars
import {size} from '../VirtualRouter';
import { decodeEntities, MergeAll, thumbnailize, randomString } from '../Functions';
import { logBehavior } from '../Data/Behavior';

/**
 * 
 * @param {{
 *   style: React.CSSProperties,
 *   session: sessionType,
 *   windowSize: size,
 *   item: titleType,
 *   isLiked: boolean,
 *   isSubscribed: boolean,
 *   saveAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   deleteAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   styleFunction?: (width: number, isOpen:boolean, isActive:boolean) => React.CSSProperties,
 *   recommOrEpisodeTitle: 'recomm' | 'episodeTitle',
 *   isClient: boolean
 * }} props 
 */
export const ExpandableGrid = (props) => {

    const [uniqueId,] = React.useState('grid_'+randomString(16));

    const item = props.item;
    const [isOpen, setIsOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    
    const [width, setWidth] = React.useState(props.windowSize.width)
    
    React.useEffect(()=>{
        setWidth(props.windowSize.width)
    }, [props.windowSize.width]);
    

    const [style, setStyle] = React.useState(
        FlexYoutubeCardStyle(width, isOpen, isActive)
    )

    React.useEffect(()=>{
        setStyle(
            FlexYoutubeCardStyle(width, isOpen, isActive)
        )
    },[width, isOpen, isActive, props.isClient])

    // place holder
    if (!item) {
        return (
            <div >
            </div>
        );
    }

    return (
        <div id={uniqueId} style={MergeAll(style, props.style || {})}
            onMouseOver={()=>{setIsActive(true)}}
            onMouseLeave={()=>{setIsActive(false)}}
        >
            <div className={"grid"} style={style.Grid}>
                <div className={"container"} style={style.Grid.Container}>
                    <div style={style.Grid.Container.ThumbnailContainer}>
                        <img
                            className={"thumbnail"}
                            style={style.Grid.Container.ThumbnailContainer.Thumbnail}
                            src={thumbnailize(item.lastEpisodeThumbnail,0,100)}
                            alt={item.title}
                            onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}
                        />
                    </div>
                    {style.Grid.Container.LabelContainer && style.Grid.Container.LabelContainer.Labels 
                    ?<div style={style.Grid.Container.LabelContainer}>
                        <ServiceAvatar style={style.Grid.Container.LabelContainer.ServiceAvatar} serviceId={item.serviceId} goto={props.goto}/>
                        <Avatar style={style.Grid.Container.LabelContainer.Avatar} imgProps={style.Grid.Container.LabelContainer.Avatar}
                            alt={item.title}
                            src={thumbnailize(item.thumbnail,40)}
                        />
                        <div style={style.Grid.Container.LabelContainer.Labels}  onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>
                            <div style={style.Grid.Container.LabelContainer.Labels.Title}>{item.title}</div>
                            {props.recommOrEpisodeTitle === 'recomm'
                                ?item.popularity && item.popularity > 1000
                                    ?<Tooltip title={"누적 조회수 입니다."}>
                                        <div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>
                                            {`조회 ${
                                                item.popularity > 10000?
                                                    `${Math.floor(item.popularity / 1000)/10} 만`
                                                :item.popularity > 1000?
                                                    `${Math.floor(item.popularity / 100)/10} 천`
                                                :   `${item.popularity}`
                                            } 회`}
                                        </div>
                                    </Tooltip>
                                    :item.rank && item.rank < 50
                                        ?<Tooltip title={"각 서비스에서 자체 선정한 랭킹입니다. 네이버의 경우 요일별로 다른 랭킹을 적용합니다."}>
                                            <div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>
                                                {`자체 랭킹 ${item.rank} 위`}
                                            </div>
                                        </Tooltip>
                                        :item.rating && item.rating > 8
                                            ?<Tooltip title={"각 서비스에서 집계중인 별점입니다."}>
                                                <div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>
                                                    {`별점 ${Math.floor(item.rating*10)/10}`}
                                                </div>
                                            </Tooltip>
                                            :<div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>{"　"}</div>
                                :<div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>{item.lastEpisodeTitle}</div>}
                        </div>
                        <IconButton
                            style={style.Grid.Container.LabelContainer.More}
                            onClick={()=>{setIsOpen(true)}}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    :<div/>}
                </div>
                {props.isClient && style.Grid.ActiveGrid?
                <div className={"activeGrid"} style={style.Grid.ActiveGrid} onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>
                    <div className={"thumbnailContainer"} style={style.Grid.ActiveGrid.ThumbnailContainer}>
                    <img
                        className={"thumbnail"}
                        style={style.Grid.ActiveGrid.ThumbnailContainer.Thumbnail}
                        src={thumbnailize(item.lastEpisodeThumbnail)}
                        alt={item.title}
                    />
                    </div>
                </div>:<div/>}
                {isOpen?
                <div className={"openGrid"} style={style.Grid.OpenGrid}>
                    <div className={"background"} style={style.Grid.OpenGrid.Background} onClick={()=>{setIsOpen(false)}} onWheel={()=>setIsOpen(false)}/>
                    <div className={"card"} style={style.Grid.OpenGrid.Card}>
                        <div className={"thumbnailContainer"} style={style.Grid.OpenGrid.Card.ThumbnailContainer}>
                            <img
                                className={"thumbnail"}
                                src={thumbnailize(item.lastEpisodeThumbnail)}
                                alt={item.lastEpisodeTitle}
                                style={style.Grid.OpenGrid.Card.ThumbnailContainer.Thumbnail}
                            />
                        </div>
                        <div style={style.Grid.OpenGrid.Card.ThumbTitleContainer}>
                            <ServiceAvatar style={style.Grid.OpenGrid.Card.ThumbTitleContainer.Avatar} serviceId={item.serviceId} goto={props.goto}/>
                            <Button className={"title"} style={style.Grid.OpenGrid.Card.ThumbTitleContainer.Title} onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>{item.title}</Button>
                        </div>
                        <div className={"description"} style={style.Grid.OpenGrid.Card.Description}>{decodeEntities(item.description)}</div>
                        <div className={"actions"} style={style.Grid.OpenGrid.Card.Actions}>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='관심 작품'><span>
                                <IconButton aria-label="add to favorites" onClick={()=>{
                                if(!props.isLiked){
                                    logBehavior('like', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.saveAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }else{
                                    logBehavior('unlike', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.deleteAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                                }}
                                color={props.isLiked?"secondary":"default"}
                                >
                                <Favorite />
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='알림 설정'><span>
                                <IconButton aria-label="share" onClick={()=>{
                                    logBehavior('subscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                if (!props.isSubscribed){
                                    props.saveAndUpdateInterest((props.isLiked?[]:[
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} },
                                    ]).concat([
                                        {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]));
                                }else{
                                    logBehavior('unsubscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.deleteAndUpdateInterest([
                                    {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                                }}
                                color={props.isSubscribed?"secondary":"default"}
                                >
                                <WatchLater secondary={"true"} />
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='유료 최신화로 이동'><span>
                                <IconButton aria-label="launch" disabled={item.lastEpisodeHref === item.lastFreeEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'latest', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.lastEpisodeId});
                                    props.goto(item.lastEpisodeHref);
                                }} >
                                <AttachMoney/>
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='무료 최신화로 이동'><span>
                                <IconButton aria-label="launch" disabled={!item.lastFreeEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'latestFree', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.lastFreeEpisodeId});
                                    props.goto(item.lastFreeEpisodeHref);
                                }} >
                                <MoneyOff/>
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='첫화로 이동'><span>
                                <IconButton aria-label="launch" disabled={!item.firstEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'first', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.firstEpisodeId});
                                    props.goto(item.firstEpisodeHref);
                                }}>
                                <FastRewind/>
                                </IconButton>
                            </span></Tooltip>
                        </div>
                    </div>
                </div>:<div/>}
            </div>
            <div className={"backgorund"} style={style.Background}/>
        </div>
    );
}

export default ExpandableGrid;