import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *   episodeId: string,
 *   title: string,
 *   thumbnail: string,
 *   href: string,
 *   isFree: number,
 *   createdAt: number,
 *   freeFrom: number,
 * }} episodeType
 */

/**
 * 
 * @param {sessionType} session 
 * @param {string} query 
 * @param {number} limit 
 * @returns {Promise<episodeType[]>}
 */
export async function readEpisodes (serviceId, titleId) {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/episode', {serviceId, titleId,}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}