import React from 'react';
import { TextField } from '@material-ui/core';

const EditableField = (props) => {

    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue);
    },[props]);

    return (props.isEditing
    ?(<TextField
        style={props.style || {}}
        defaultValue={value || ""}
        value={value || ""}
        onChange={(event)=>setValue(event.target.value)}
        onKeyPress={(event)=> {
            let newData = {}; newData[props.field] = event.target.value;
            if (event.key === "Enter") props.update(newData);
        }}
        onBlur={(event)=> {
            let newData = {}; newData[props.field] = event.target.value;
            props.update(newData);
        }}
    />)
    :<div style={props.style}>{props.defaultValue}</div>);
}

export default EditableField;