import React from 'react';
import {YoutubeCard as YoutubeCardStyle, FlexYoutubeCard as FlexYoutubeCardStyle} from '../Styles';
import {Avatar, } from '@material-ui/core';
import ServiceAvatar from './ServiceAvatar';

import { decodeEntities, MergeAll, thumbnailize } from '../Functions';

/**
 * @typedef {import('../Data/Featured').featuredType} featuredType
 * @typedef {import('../Data/Featured').interestFetchType} interestFetchType
 * @typedef {import('../Data/Featured').sessionType} sessionType
 * @typedef {import('../VirtualRouter').size} size
 * 
 * @param {{
 *   style: React.CSSProperties,
 *   session: sessionType,
 *   windowSize: size,
 *   item: featuredType,
 *   onClick: () => void,
 *   logBehavior:(session: sessionType, what: string, detail: {any}) => Promise<boolean>,
 *   styleFunction?: (parentWidth: number, parentHeight: number, isOpen:boolean, isActive:boolean) => React.CSSProperties,
 * }} props 
 */
const VSCard = (props) => {

    const item = props.item;
    const [isActive, setIsActive] = React.useState(false);
    
    const [style, setStyle] = React.useState(
        props.styleFunction
            ?props.styleFunction(props.windowSize.width, props.windowSize.height, false, isActive)
            :props.windowSize.width > 640
                ?FlexYoutubeCardStyle(props.windowSize.width, false, isActive)
                :YoutubeCardStyle(props.windowSize.width, false, isActive)
    );
    
    React.useEffect(()=>{
        setStyle(
            props.styleFunction
                ?props.styleFunction(props.windowSize.width, props.windowSize.height, false, isActive)
                :props.windowSize.width > 640
                    ?FlexYoutubeCardStyle(props.windowSize.width, false, isActive)
                    :YoutubeCardStyle(props.windowSize.width, false, isActive)
        );
        
    }, [props.windowSize.width, isActive, props.styleFunction, props]);

    // place holder
    if (!item) {
        return (
            <div style={style}>
            </div>
        );
    }

    return (
        <div style={MergeAll(style, props.style || {})}
            onMouseOver={()=>{setIsActive(true)}}
            onMouseLeave={()=>{setIsActive(false)}}
            onClick={props.onClick}
        >
            <div className={"grid"} style={style.Grid}>
                <div className={"container"} style={style.Grid.Container}>
                    <div style={MergeAll(style.Grid.Container.ThumbnailContainer, {backgroundImage: `url(${thumbnailize(item.lastEpisodeThumbnail)})`, filter: 'blur(10px)'})}>
                    </div>
                    <div style={MergeAll(style.Grid.Container.ThumbnailContainer, {position:'absolute', top: 0})}>
                        <img
                            className={"thumbnail"}
                            style={style.Grid.Container.ThumbnailContainer.Thumbnail}
                            src={thumbnailize(item.thumbnail)}
                            alt={item.title}
                        />
                    </div>
                    {item.description
                        ?<div style={style.Grid.Container.Description}>
                            {decodeEntities(item.description)}
                        </div>
                        :<></>}
                    {style.Grid.Container.LabelContainer && style.Grid.Container.LabelContainer.Labels 
                    ?<div style={style.Grid.Container.LabelContainer}>
                        <ServiceAvatar style={style.Grid.Container.LabelContainer.Avatar} serviceId={item.serviceId} goto={props.goto}/>
                        <Avatar style={style.Grid.Container.LabelContainer.Avatar} imgProps={style.Grid.Container.LabelContainer.Avatar}
                            alt={item.title}
                            src={thumbnailize(item.lastEpisodeThumbnail,40)}
                        />
                        <div style={style.Grid.Container.LabelContainer.Labels}>
                            <div style={style.Grid.Container.LabelContainer.Labels.Title}>{item.title}</div>
                            <div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>{item.lastEpisodeTitle}</div>
                        </div>
                    </div>
                    :<div/>}
                </div>
            </div>
            <div className={"backgorund"} style={style.Background}/>
        </div>
    );
}

export default VSCard;