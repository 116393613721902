import { Button } from '@material-ui/core';
import React from 'react';
import { MergeAll, } from '../Functions';
import {NaverLogo, DaumLogo, KakaoLogo, ToomicsLogo, ToptoonLogo, LezhinLogo, ComicoLogo} from '../Logos';
import {Tutorial as TutorialStyle} from '../Styles';

/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
 */
const Tutorial = ({session, logBehavior, goto, windowSize}) => {

    window.canonicalPath = '/tutorial';

    const pageStyle = TutorialStyle(windowSize.width, windowSize.height, 0).Pages.Page;
    const [scrollY,setScrollY] = React.useState(0);

    React.useEffect(()=>{
        let intervalId = setInterval(()=>{
            setScrollY((document.querySelector('#tutorialRoot') || {scrollTop:0}).scrollTop);
        }, 10);
        return () => clearInterval(intervalId)
    },[]);

    const pages = [
        <div key={"pg1"} style={MergeAll(pageStyle, {backgroundColor:"#00D85E"})}>
            {"매일밤 기다려지는 웹툰, 어떻게 보고 계세요?"}
        </div>,
        <div key={"pg2"} style={MergeAll(pageStyle, {backgroundColor:"#FF3042"})}>
            {"앱 설치만 다섯개째, 알림설정은 제각각"}
        </div>,
        <div key={"pg3"} style={MergeAll(pageStyle, {backgroundColor:"#FFD200"})}>
            {"쿠키를 굽는건 괜찮은데 어디서 연재하는지 외우는게 더 어려웠죠."}
        </div>,
        <div key={"pg4"} style={MergeAll(pageStyle, {backgroundColor:"#000000"})}>
            {"이제는 기억할 필요 없어요."}
        </div>,
        <div key={"pg5"} style={MergeAll(pageStyle, {backgroundColor:"#FFFFFF", color: "#000000"})}>
            {"알림이 오면 들어가서 쿠키만 구우면 됩니다."}
        </div>,
        <div key={"pg6"} style={MergeAll(pageStyle, {backgroundColor:"#FF4842"})}>
            {"어떻게 하냐구요?"}
        </div>,
        <div key={"pg7"} style={MergeAll(pageStyle, {backgroundColor:"#F40002"})}>
            {"모두 잊어버리세요. 그리고 딱 하나만 기억하세요."}
        </div>,
        <div key={"pg8"} style={MergeAll(pageStyle, {backgroundColor:"skyblue"})}>
            {"오늘의 웹툰."}
        </div>,
    ];
    const pageHeight = (pageStyle.height + pageStyle.paddingTop + pageStyle.paddingBottom);
    const scrollHeight = (pages.length -1) * pageHeight;
    const currentPage = Math.round((scrollY) / pageHeight);
    const progress = scrollY / scrollHeight;

    const style = TutorialStyle(windowSize.width, windowSize.height, progress, pages.length);
    
    React.useEffect(()=>{
        const intervalId = setInterval(()=>{
            if (currentPage === pages.length-1){
                logBehavior('tutorialDone', {type:'waitFullPresentation', currentPage})
                goto('/')
            }else{
                document.querySelector('#tutorialRoot').scrollTo(0, Math.min(pages.length-1,currentPage+1)*pageHeight)
            }
        }, 2000)
        return ()=>clearInterval(intervalId)
    },[currentPage, goto, logBehavior, pageHeight, pages.length, session]);

    return (
        <div id={"tutorialRoot"} style={style}>
            <div className={"pages"} style={style.Pages}>
                {pages}
            </div>
            <div className={"phoneBG"} style={style.PhoneBG} />
            <div className={"phoneMask"} style={style.PhoneMask}>
                <div className={"logoAnimated"} style={style.LogoAnimated}>
                    <img className={"logo"} style={style.LogoAnimated.Child[0]} src={NaverLogo} alt={"naver"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[1]} src={DaumLogo} alt={"daum"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[2]} src={KakaoLogo} alt={"kakao"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[3]} src={LezhinLogo} alt={"lezhin"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[4]} src={ToptoonLogo} alt={"toptoon"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[5]} src={ToomicsLogo} alt={"toomics"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[6]} src={ComicoLogo} alt={"comico"}/>
                    <img className={"logo"} style={style.LogoAnimated.Child[7]} src={'/logo512.png'} alt={"오늘의웹툰"}/>
                </div>
            </div>
            <img className={"phone"} src={'/phone.png'} alt={"phone"} style={style.Phone}/>
            <div className={"pageIndicator"}>
                {pages.map((row, index)=> (
                    <div className={"indeicatorCircle"} style={MergeAll({},index === currentPage?{}:{})}></div>
                ) )}
            </div>
            <div className={"bottomButtonContainer"} style={style.BottomButtonContainer}>
                <Button style={style.BottomButtonContainer.ChildButton} onClick={()=>{    
                    if (currentPage !== pages.length-1){
                        logBehavior('tutorialDone', {type:'skipPresentation', currentPage})
                    }else{
                        logBehavior('tutorialDone', {type:'waitFullPresentation', currentPage})
                    }
                    goto('/')
                }} >닫기</Button>
                <Button style={style.BottomButtonContainer.ChildButton} onClick={()=>{
                    logBehavior('move', {type:'goDown', currentPage})
                    window.scrollTo(0, Math.min(pages.length-1,currentPage+1)*pageHeight)
                }} >다음</Button>
                <Button style={style.BottomButtonContainer.ChildButton} onClick={()=>{
                    logBehavior('move', {type:'goUp', currentPage})
                    window.scrollTo(0, Math.max(0,currentPage-1)*pageHeight)
                }} >위로</Button>
                <Button style={style.BottomButtonContainer.ChildButton} onClick={()=>{
                    logBehavior('move', {type:'goTop', currentPage})
                    window.scrollTo(0, 0)
                }} >다시보기</Button>
            </div>
        </div>
    );
}

export default Tutorial;