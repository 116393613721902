import { Button } from '@material-ui/core';
import React from 'react';
import EditableTable from '../../Component/Table/EditableTable';
import {createMeta, listMetas, updateMeta} from '../../Data/Meta';
import {getTitles, readTitles, } from '../../Data/Title';
import { thumbnailize } from '../../Functions';

const randomString = (length) =>
  new Array(length).fill(0)
    .map((x) => String.fromCharCode(65 + Math.floor(Math.random() * 2) * 32 + Math.floor(Math.random() * 26)))
    .join("");

const Meta = (props) => {

    const [metas, setMetas] = React.useState([]);
    const [titles, setTitles] = React.useState([]);
    const [range, setrange] = React.useState([0,10]);

    const [notExistMetas, setNotExistMetas] = React.useState([]); 

    React.useEffect(()=>{
        (async ()=>{
            setMetas((await listMetas()).filter(meta=> !meta.historicalSetting || !meta.fictionalSetting || !meta.genre || !meta.perspective || !meta.drawingStyle));
        })();
    },[props.session])

    React.useEffect(()=>{
        if (metas.length > 0){
            const metaKeys = metas.map(meta => `${meta.serviceId}:${meta.titleId}`).sort().join(',')
            const titleKeys = titles.map(meta => `${meta.serviceId}:${meta.titleId}`).sort().join(',')
            if (metaKeys === titleKeys){
                return;
            }
            (async ()=>{
                setTitles(await getTitles(metas.slice(...range).map(meta => [meta.serviceId,meta.titleId])));
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[metas, props.session, range])

    React.useEffect(()=>{

        (async()=>{
            const allTitles = await readTitles(null, 10000);
            const allMetas = await listMetas();

            const allMetaKeys = new Set(allMetas.map(row => `${row.serviceId}:${row.titleId}`));

            const notExistingTitles = allTitles.filter(title => !allMetaKeys.has(`${title.serviceId}:${title.titleId}`));

            setNotExistMetas(notExistingTitles.map(({serviceId, titleId})=> ({serviceId, titleId, historicalSetting:null, fictionalSetting:null, genre:null, perspective:null, deployment:null, drawingStyle:null})));
        })();


    },[props.session]);

    const [columnsFunction, setColumnsFunction] = React.useState(()=>(row,index)=>{
        const title = titles.filter(title => title.titleId === row.titleId && title.serviceId === row.serviceId).concat([{}])[0]
        console.log(title, )
        return [
            {type:'default', defaultValue: row.titleId, field: 'titleId'},
            {type:'default', defaultValue: row.serviceId, field: 'serviceId'},
            {type:'dummy', defaultValue: <div style={{width: 200}}><p>{title.title}</p><br/>{title.description}</div>, field: 'title'},
            {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.thumbnail)} alt={title.title}/></a>), field: 'thumbnail'},
            {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.firstEpisodeThumbnail)} alt={title.title}/></a>), field: 'firstEpisodeThumbnail'},
            {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.lastFreeEpisodeThumbnail)} alt={title.title}/></a>), field: 'lastFreeEpisodeThumbnail'},
            {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.lastEpisodeThumbnail)} alt={title.title}/></a>), field: 'lastEpisodeThumbnail'},
            {isEditing: true, type:'select', defaultValue: row.historicalSetting || ('__'+randomString(12)), field: 'historicalSetting', options:[
                'contemporary', 'middleAgeEast', 'middleAgeWest', 'modernEast', 'modernWest', 'future'
            ].map(value => ({key:value, value}))},
            {isEditing: true, type:'select', defaultValue: row.fictionalSetting || ('__'+randomString(12)), field: 'fictionalSetting', options:[
                'nonfiction', 'scifi', 'fantasy', 'nearnonfiction', 
            ].map(value => ({key:value, value}))},
            {isEditing: true, type:'select', defaultValue: row.genre || ('__'+randomString(12)), field: 'genre', options:[
                'romance', 'action', 'comedy', 'thriller', 'growingup', 'healing',
            ].map(value => ({key:value, value}))},
            {isEditing: true, type:'select', defaultValue: row.perspective || ('__'+randomString(12)), field: 'perspective', options:[
            'girl', 'boy', 
            ].map(value => ({key:value, value}))},
            {isEditing: true, type:'select', defaultValue: row.deployment || ('__'+randomString(12)), field: 'deployment', options:[
                'mono', 'ensemble'
            ].map(value => ({key:value, value}))},
            {isEditing: true, type:'select', defaultValue: row.drawingStyle || ('__'+randomString(12)), field: 'drawingStyle', options:[
                'semimanga', 'hyperreal', 'real', 'semicartoon', 'semielse', 'deformercartoon', 'deformermanga', 'deformerelse'
            ].map(value => ({key:value, value}))},                        
        ]
    });

    React.useEffect(()=>{
        setColumnsFunction(()=>(row,index)=>{
            const title = titles.filter(title => title.titleId === row.titleId && title.serviceId === row.serviceId).concat([{}])[0]
            return [
                {type:'default', defaultValue: row.titleId, field: 'titleId'},
                {type:'default', defaultValue: row.serviceId, field: 'serviceId'},
                {type:'dummy', defaultValue: <div style={{width: 200}}><p>{title.title}</p><br/>{title.description}</div>, field: 'title'},
                {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.thumbnail)} alt={title.title}/></a>), field: 'thumbnail'},
                {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.firstEpisodeThumbnail)} alt={title.title}/></a>), field: 'firstEpisodeThumbnail'},
                {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.lastFreeEpisodeThumbnail)} alt={title.title}/></a>), field: 'lastFreeEpisodeThumbnail'},
                {type:'dummy', defaultValue: (<a href={title.firstEpisodeHref} target={'blank'}><img style={{width:200, height: 200, objectFit: 'contain'}} src={thumbnailize(title.lastEpisodeThumbnail)} alt={title.title}/></a>), field: 'lastEpisodeThumbnail'},
                {isEditing: true, type:'select', defaultValue: row.historicalSetting || ('__'+randomString(12)), field: 'historicalSetting', options:[
                    'contemporary', 'middleAgeEast', 'middleAgeWest', 'modernEast', 'modernWest', 'future'
                ].map(value => ({key:value, value}))},
                {isEditing: true, type:'select', defaultValue: row.fictionalSetting || ('__'+randomString(12)), field: 'fictionalSetting', options:[
                    'nonfiction', 'scifi', 'fantasy', 'nearnonfiction', 
                ].map(value => ({key:value, value}))},
                {isEditing: true, type:'select', defaultValue: row.genre || ('__'+randomString(12)), field: 'genre', options:[
                    'romance', 'action', 'comedy', 'thriller', 'growingup', 'healing',
                ].map(value => ({key:value, value}))},
                {isEditing: true, type:'select', defaultValue: row.perspective || ('__'+randomString(12)), field: 'perspective', options:[
                'girl', 'boy', 
                ].map(value => ({key:value, value}))},
                {isEditing: true, type:'select', defaultValue: row.deployment || '', field: 'deployment', options:[
                    'mono', 'ensemble'
                ].map(value => ({key:value, value}))},
                {isEditing: true, type:'select', defaultValue: row.drawingStyle, field: 'drawingStyle', options:[
                    'semimanga', 'hyperreal', 'real', 'semicartoon', 'semielse', 'deformercartoon', 'deformermanga', 'deformerelse'
                ].map(value => ({key:value, value}))},                        
            ]
        });
    },[titles])

    const [updateGenerator, setUpdateGenerator] = React.useState(()=>(row,index)=>async (newValue)=>{
        let newRow = Object.assign({}, row);
        newRow = Object.assign(newRow, newValue);
        await updateMeta(newRow)
        const newMetas = metas.map(meta => (meta.serviceId === row.serviceId && meta.titleId === row.titleId)?newRow:meta)
        setMetas(newMetas);
    });

    React.useEffect(()=>{
        setUpdateGenerator(()=>(row,index)=>async (newValue)=>{
            let newRow = Object.assign({}, row);
            newRow = Object.assign(newRow, newValue);
            await updateMeta(newRow)
            const newMetas = metas.map(meta => (meta.serviceId === row.serviceId && meta.titleId === row.titleId)?newRow:meta)
            setMetas(newMetas);
        });
    },[metas, props.session])

    const [partialData, setPartialData] = React.useState(metas.slice(...range).filter(meta => titles.map(title => `${title.serviceId}:${title.titleId}`).indexOf(`${meta.serviceId}:${meta.titleId}`)>=0))
    React.useEffect(()=>{
        setPartialData(metas.slice(...range).filter(meta => titles.map(title => `${title.serviceId}:${title.titleId}`).indexOf(`${meta.serviceId}:${meta.titleId}`)>=0))
    },[metas, range, titles])

    return (<>
        <div style={{textAlign:'center'}}>
            {"현재 범위"}
            {JSON.stringify(range)}
            {"총 문서수"}
            {metas.length}
            <Button onClick={()=>{
                setrange([range[0]+10, range[1]+10])
            }}>{"다음"}</Button>
            <Button
                disabled={notExistMetas.length === 0}
                onClick={()=>{
                    (async ()=>{
                        for (const row of notExistMetas){
                            await createMeta(row);
                        }
                        setNotExistMetas([]);
                    })();
                }}
            >{"타이틀-메타 싱크"}</Button>
        </div>
        <div style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
            <EditableTable
                name={"meta"}
                data={partialData}
                headerButtonHeaders={[]}
                columnHeaders={[
                    'titleId', 'serviceId', 'title', 'thumbnail', `firstEpisodeThumbnail`, `lastFreeEpisodeThumbnail`, `lastEpisodeThumbnail`,
                    'historicalSetting', 'fictionalSetting', 'genre',
                    'perspective', 'deployment', 'drawingStyle'
                ]}
                defaultSortCol={[1,'asc']}
                headerButtonsFunction={(row,index)=>[]}
                columnsFunction={columnsFunction}
                updateGenerator={updateGenerator}
            />
        </div>
    </>)
};

export default Meta;