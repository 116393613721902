import { Button } from '@material-ui/core';
import React from 'react';

const CollapseList = ({title, body}) => {
    const [isOpened, setIsOpend] = React.useState(false);

    return (<>
        <Button style={{borderBottom: '0.5px lightgray solid', fontSize: '0.8rem', textAlign: 'left'}} onClick={()=>{setIsOpend(!isOpened)}}>{title}</Button>
        {isOpened
            ?<div style={{padding:20, fontSize: '0.8rem', textAlign: 'left'}}>
                {body}
            </div>
            :<></>}
    </>);


};

export default CollapseList;