import axios from 'axios';
import ss from './Session';

/**
 * 
 * @param {import('./Title').titleType} title 
 * @param {import('./Title').titleType[]} histories 
 * @param {number} limit 
 * @param {boolean} withPreference 
 */
export const listRecomm = async (title, histories = [], negativeHistories = [], limit = 3, withPreference=false) => {
    let ret = [];
    let preference = {};
    let negativePreference = {};
    let progress = 0;

    let {serviceId, titleId} = title || {};

    let req = {
        serviceId, titleId,
        histories: histories.map(row => ([row.serviceId, row.titleId])),
        negativeHistories: negativeHistories.map(row => ([row.serviceId, row.titleId])),
        limit,
        start_index: 1,
    };
    let res = await axios.post('https://challenge-api.webtoon.today/timeline', req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        preference = res.data.preference || {};
        negativePreference = res.data.negativePreference || {};
        progress = res.data.progress || 0;
        ret = res.data.data;
    }else{
        console.error(res.code, res.data);
    }

    if (withPreference){
        return {data: ret, preference: Object.assign(preference, {backup: histories.length, progress}), negativePreference};
    }else{
        return ret;
    }
};