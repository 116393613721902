import React from 'react';
import {ExpandableGrid as ExpandableGridStyle} from '../Styles';
import {Avatar, Button, IconButton, Tooltip, } from '@material-ui/core';
import {WatchLater, AttachMoney, MoneyOff, FastRewind, Favorite, MoreHoriz as MoreIcon } from '@material-ui/icons';
import ServiceAvatar from './ServiceAvatar';

// @Type
// eslint-disable-next-line no-unused-vars
import {sessionType, googleResponseType, facebookResponseType} from '../Data/Session';
// eslint-disable-next-line no-unused-vars
import {interestFetchType, interestType} from '../Data/Interest';
// eslint-disable-next-line no-unused-vars
import {readTitles, titleType} from '../Data/Title';
// eslint-disable-next-line no-unused-vars
import {size} from '../VirtualRouter';
import { MergeAll, thumbnailize } from '../Functions';
import { logBehavior } from '../Data/Behavior';

/**
 * 
 * @param {{
 *   style: React.CSSProperties,
 *   session: sessionType,
 *   windowSize: size,
 *   item: titleType,
 *   isLiked: boolean,
 *   isSubscribed: boolean,
 *   saveAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   deleteAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   styleFunction?: (width: number, isOpen:boolean, isActive:boolean) => React.CSSProperties,
 *   width: number, heightRatio: number, marginRatio: number,
 *   isClient: boolean
 * }} props 
 */
export const InstaGrid = (props) => {

    const item = props.item;
    const [isOpen, setIsOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [isWheeling, setIsWheeling] = React.useState(false);

    const [openTimeoutId, setOpenTimeoutId] = React.useState(null);

    React.useEffect(()=>{
        if (isActive){
            if (!openTimeoutId){
                setOpenTimeoutId(setTimeout(()=>{
                    setIsOpen(true);
                    clearTimeout(openTimeoutId)
                    setOpenTimeoutId(null)
                },1000))
            }
        }else{
            if (openTimeoutId){
                clearTimeout(openTimeoutId);
                setOpenTimeoutId(null);
            }
        }

    },[isActive, openTimeoutId])

    React.useEffect(()=>{
        if(isOpen){
            logBehavior('longpress', {title: item.title})
        }
    },[isOpen, item.title, props])
    
    React.useEffect(()=>{
        if (isWheeling && openTimeoutId){
            clearTimeout(openTimeoutId);
            setOpenTimeoutId(null);
            setIsActive(false);
        }
    },[isWheeling, openTimeoutId])

    React.useEffect(()=>{
        if (!isActive && !isOpen && openTimeoutId){
            clearTimeout(openTimeoutId);
            setOpenTimeoutId(null);
            props.goto(`/detail/${item.serviceId}/${item.titleId}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isActive, isOpen, item.serviceId, item.titleId, openTimeoutId])

    const [parentWidth, setParentWidth] = React.useState(props.windowSize.width)
    
    React.useEffect(()=>{
        setParentWidth(props.windowSize.width)
    }, [props.windowSize.width]);

    const [style, setStyle] = React.useState( ExpandableGridStyle(parentWidth, isOpen, isActive, props.width, props.heightRatio, props.marginRatio))

    React.useEffect(()=>{

        setStyle(ExpandableGridStyle(parentWidth, isOpen, isActive, props.width, props.heightRatio, props.marginRatio))
    },[isActive, isOpen, parentWidth, props.heightRatio, props.marginRatio, props.width])

    // place holder
    if (!item) {
        return (
            <div >
            </div>
        );
    }

    return (
        <div style={MergeAll(style, props.style || {})}>
            <div className={"grid"} style={style.Grid}>
                <div className={"container"} style={style.Grid.Container}>
                    <div style={style.Grid.Container.ThumbnailContainer}>
                        <div className={"Thumbnail"}
                            style={MergeAll(style.Grid.Container.ThumbnailContainer.Thumbnail,{
                                backgroundImage: `url('${thumbnailize(item.lastEpisodeThumbnail, Math.min(props.width, 80))}')`
                            })}
                            onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}
                        />
                    </div>
                    {style.Grid.Container.LabelContainer && style.Grid.Container.LabelContainer.Labels 
                    ?<div style={style.Grid.Container.LabelContainer}>
                        <ServiceAvatar style={style.Grid.Container.LabelContainer.Avatar} serviceId={item.serviceId} goto={props.goto}/>
                        <Avatar style={style.Grid.Container.LabelContainer.Avatar} imgProps={style.Grid.Container.LabelContainer.Avatar}
                            alt={item.title}
                            src={thumbnailize(item.thumbnail)}
                        />
                        <div style={style.Grid.Container.LabelContainer.Labels}  onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>
                            <div style={style.Grid.Container.LabelContainer.Labels.Title}>{item.title}</div>
                            <div style={style.Grid.Container.LabelContainer.Labels.Subtitle}>{item.lastEpisodeTitle}</div>
                        </div>
                        <IconButton
                            style={style.Grid.Container.LabelContainer.More}
                            onClick={()=>{setIsOpen(true)}}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    :<div/>}
                </div>
                {props.isClient && style.Grid.ActiveGrid?
                <div className={"activeGrid"} style={style.Grid.ActiveGrid} onClick={()=>{}}
                    onMouseDown={()=>{setIsActive(true)}}
                    onMouseUp={()=>{setIsActive(false)}}
                    onMouseLeave={()=>{setIsActive(false)}}
                    onTouchStart={()=>{setIsActive(true); setIsWheeling(false);}}
                    onTouchEnd={()=>{setIsActive(false)}}
                    onTouchCancel={()=>{setIsActive(false)}}
                    onTouchMove={(event)=>{setIsWheeling(true)}}
                >
                    <div className={"thumbnailContainer"} style={style.Grid.ActiveGrid.ThumbnailContainer}>
                    <div className={"Thumbnail"}
                        style={MergeAll(style.Grid.ActiveGrid.ThumbnailContainer.Thumbnail,{
                            backgroundImage: `url('${thumbnailize(item.lastEpisodeThumbnail)}')`
                        })}
                    />
                    </div>
                </div>:<div/>}
                {isOpen?
                <div className={"openGrid"} style={style.Grid.OpenGrid}
                    onTouchMove={()=>setIsOpen(false)}
                >
                    <div className={"background"} style={style.Grid.OpenGrid.Background}
                        onClick={()=>{setIsOpen(false)}}
                        onWheel={()=>setIsOpen(false)}
                    />
                    <div className={"card"} style={style.Grid.OpenGrid.Card}>
                        <div className={"thumbnailContainer"} style={style.Grid.OpenGrid.Card.ThumbnailContainer}>
                            <img
                                className={"thumbnail"}
                                src={thumbnailize(item.lastEpisodeThumbnail)}
                                alt={item.lastEpisodeTitle}
                                style={style.Grid.OpenGrid.Card.ThumbnailContainer.Thumbnail}
                            />
                        </div>
                        <div style={style.Grid.OpenGrid.Card.ThumbTitleContainer}>
                            <ServiceAvatar style={style.Grid.OpenGrid.Card.ThumbTitleContainer.Avatar} serviceId={item.serviceId} goto={props.goto}/>
                            <Button className={"title"} style={style.Grid.OpenGrid.Card.ThumbTitleContainer.Title} onClick={()=>{props.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>{item.title}</Button>
                        </div>
                        <div className={"description"} style={style.Grid.OpenGrid.Card.Description}>{item.description}</div>
                        <div className={"actions"} style={style.Grid.OpenGrid.Card.Actions}>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='관심 작품'><span>
                                <IconButton aria-label="add to favorites" onClick={()=>{
                                if(!props.isLiked){
                                    logBehavior('like', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.saveAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }else{
                                    logBehavior('unlike', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.deleteAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                                }}
                                color={props.isLiked?"secondary":"default"}
                                >
                                <Favorite />
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='알림 설정'><span>
                                <IconButton aria-label="share" onClick={()=>{
                                    logBehavior('subscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                if (!props.isSubscribed){
                                    props.saveAndUpdateInterest((props.isLiked?[]:[
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} },
                                    ]).concat([
                                        {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]));
                                }else{
                                    logBehavior('unsubscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.deleteAndUpdateInterest([
                                    {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                                }}
                                color={props.isSubscribed?"secondary":"default"}
                                >
                                <WatchLater secondary={"true"} />
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='유료 최신화로 이동'><span>
                                <IconButton aria-label="launch" disabled={item.lastEpisodeHref === item.lastFreeEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'latest', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.lastEpisodeId});
                                    props.goto(item.lastEpisodeHref);
                                }} >
                                <AttachMoney/>
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='무료 최신화로 이동'><span>
                                <IconButton aria-label="launch" disabled={!item.lastFreeEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'latestFree', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.lastFreeEpisodeId});
                                    props.goto(item.lastFreeEpisodeHref);
                                }} >
                                <MoneyOff/>
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip style={style.Grid.OpenGrid.Card.Actions.Button} title='첫화로 이동'><span>
                                <IconButton aria-label="launch" disabled={!item.firstEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'first', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.firstEpisodeId});
                                    props.goto(item.firstEpisodeHref);
                                }}>
                                <FastRewind/>
                                </IconButton>
                            </span></Tooltip>
                        </div>
                    </div>
                </div>:<div/>}
            </div>
            <div className={"backgorund"} style={style.Background}/>
        </div>
    );
}

export default InstaGrid;