import React from 'react';
import {SIGNATURE_COLOR} from '../Styles';
import { Avatar, IconButton} from '@material-ui/core';
import {NewReleases, Search as SearchIcon, Settings as SettingsIcon} from '@material-ui/icons';
import { thumbnailize } from '../Functions';
import '../Style/Component/Appbar.scss';

/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
 */
const AppBar = (props) => {

    
    const styleFunc = (parentWidth,path) => {

        const HighlightPos = (path) =>     
            path.startsWith('/setting')  ?3:
            path.startsWith('/match')    ?2:
            path.startsWith('/search')   ?1:
                                        0
        ;

        return ({
            display: (path.startsWith('/onboarding') || path.startsWith('/tutorial'))?'none':'flex',
            CurrPos: HighlightPos(path),
            Indicator: {
                left: (parentWidth/4) * HighlightPos(path) + 4,
            }
        });
    }

    const style = styleFunc(props.windowSize.width, props.path);
    return (
        <div className={"Appbar"}>
            <div className={"Button"} style={{...style, ...(style.CurrPos===0?{}:{filter: 'grayscale(1)'}) }}>
                <IconButton
                    onClick={()=>{
                        props.goto("/", "tabbar")
                        props.setQuery("")
                    }}
                >
                    <Avatar src={thumbnailize("https://webtoon.today/logo512.png",40)} alt={"webtoon today"} variant={"rounded"}/>
                </IconButton>
            </div>
            <div className={"Button"} style={style.CurrPos===1?{}:{filter: 'grayscale(1)'}}>
                <IconButton
                    onClick={()=>{props.goto('/search', "tabbar")}}
                >
                    <SearchIcon style={{color: SIGNATURE_COLOR}}/>
                </IconButton>
            </div>
            <div className={"Button"} style={style.CurrPos===2?{}:{filter: 'grayscale(1)'}}>
                <IconButton
                    onClick={()=>{props.goto('/match', "tabbar")}}
                >
                    <NewReleases style={{color: SIGNATURE_COLOR}}/>
                </IconButton>
            </div>
            <div className={"Button"}>
                <IconButton
                    onClick={()=>{props.goto('/setting', "tabbar")}}>
                    {props.session.userid
                        ?<Avatar className={"Avatar"} src={props.session.image||""} alt={"profile"}/>
                        :<SettingsIcon/>}
                </IconButton>
            </div>
            <div className={"Indicator"} style={style.Indicator}></div>
        </div>
    );
}

export default AppBar;